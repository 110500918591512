export enum ErrorCode {
  ApiError = 'apiError',
  AuthenticationError = 'authenticationError',
  EnvError = 'envError',
  ThumbnailError = 'thumbnailError',
  FileError = 'fileError',
  FileUrlError = 'fileUrlError',
  ApiUploadFileError = 'apiUploadFileError',
  CreateProductDefinitionError = 'createProductDefinitionError',
  UpdateProductDefinitionError = 'updateProductDefinitionError',
  DeleteProductDefinitionError = 'deleteProductDefinitionError',
  DuplicateProductDefinitionError = 'duplicateProductDefinitionError',
  InvalidProductDefinitionOutputError = 'invalidProductDefinitionOutputError',
  CacheError = 'cacheError',
  GenerateOutputError = 'generateOutputError',
  ProductError = 'productError',
  ProductReleaseError = 'productReleaseError',
  ExtractZipFileError = 'extractZipFileError',
  EmptyTopLevelError = 'emptyTopLevelError',
  EditProductDefinitionError = 'editProductDefinitionError',
  InvalidURLError = 'invalidURLError',
  ServiceUnavailableError = 'serviceUnavailableError',
  AxiosResponseError = 'axiosResponseError',
  UnexpectedError = 'unexpectedError',
  UserInfoError = 'userInfoError',
  EmptyFileError = 'emptyFileError',
  ProductsFetchError = 'productsFetchError',
  VariantFetchError = 'variantFetchError',
  VariantPostError = 'variantPostError',
  VariantOutputsFetchError = 'variantOutputsFetchError',
  BIM360AccountsFetchError = 'bim360AccountsFetchError',
  BIM360ProjectsFetchError = 'bim360ProjectsFetchError',
  BIM360ProjectFetchError = 'bim360ProjectFetchError',
  BIM360FoldersFetchError = 'bim360FoldersFetchError',
  BIM360FolderDocumentsFetchError = 'BIM360FolderDocumentsFetchError',
  BIM360FolderCreationError = 'BIM360FolderCreationError',
  BIM360ManifestFetchError = 'bim360ManifestFetchError',
  InsufficientArguments = 'insufficientArguments',
  VersionInfoLoadError = 'versionInfoLoadError',
  CodeRunnerError = 'codeRunnerError',
  CodeValidationError = 'codeValidationError',
  VariantLogsFetchError = 'variantLogsFetchError',
  HubProjectFolderFetchError = 'hubProjectFolderFetchError',
  HubsFetchError = 'hubsFetchError',
  HubProjectsFetchError = 'hubProjectsFetchError',
  CheckPermissionError = 'checkPermissionError',
  SelectedRFAInstanceError = 'selectedRFAInstanceError',
}
