import { DCInput, InputType, OutputType, PostVariantInput } from '@adsk/offsite-dc-sdk';
import { GenerateOutputError, getUUID } from 'mid-utils';
import {
  GenerateOutputsResult,
  InventorInput,
  InventorOutput,
  InventorOutputFileInfo,
  inventorOutputTypes,
  UploadContentResult,
} from '../interfaces/inventorAutomation';
import { LogLevel } from '../interfaces/log';
import {
  ProductDefinition,
  ProductDefinitionInputParameter,
  ProductDefinitionOutput,
} from '../interfaces/productDefinitions';
import { uploadFile, DataCategory } from './cloudStorage';
import { compressFolder, deleteFile } from './filesystem';
import { logToFile, saveToFile } from './tools';
import { generateOutputs, getModelStates } from './inventor';

const PublishUtils = {
  // returns a promise that resolves with the uploaded content result.
  uploadContentAsFile: async (
    projectId: string,
    content: string,
    fileName: string,
    fileExtension: string,
    category: DataCategory,
  ): Promise<UploadContentResult> => {
    const filePath = await saveToFile(content, fileName, fileExtension);
    const objectKey = await uploadFile(projectId, filePath, category, 'application/json');
    return { filePath, objectKey };
  },

  // returns a promise that resolves with the Output Files generated.
  getGeneratedOutputFiles: async (productDefinition: ProductDefinition): Promise<InventorOutputFileInfo[]> => {
    // generate the output files through local Inventor
    const generateOutputsResult = await PublishUtils.generateOutputFiles(productDefinition);
    if (!generateOutputsResult.success) {
      logToFile(`Failed to generate output files from: ${JSON.stringify(productDefinition)}`, LogLevel.Fatal);
      // TODO: need to define what to report in case of a failure
      throw new GenerateOutputError(generateOutputsResult.report, {
        report: generateOutputsResult.report,
      });
    }

    return generateOutputsResult.outputFiles!;
  },

  // returns a promise that resolves with the object key of dataset uploaded.
  getObjectKeyAfterUploadTopLevelFolder: async (projectId: string, topLevelFolder: string): Promise<string> => {
    logToFile('Start upload zip file (dataset) to oss', LogLevel.Info);
    const zipFilePath = await compressFolder(topLevelFolder);
    const datasetObjectKey = await uploadFile(projectId, zipFilePath, DataCategory.Inputs);
    // Delete a zip file
    await deleteFile(zipFilePath);
    logToFile(`Upload zip file (dataset) successfully ${datasetObjectKey}`, LogLevel.Info);

    return datasetObjectKey;
  },

  // returns a promise that resolves with the object key of thumbnail after upload it.
  getObjectKeyAfterUploadThumbnail: async (projectId: string, thumbnailFilePath: string): Promise<string> => {
    logToFile('Start upload thumbnail to oss', LogLevel.Info);
    const thumbnailObjectKey = await uploadFile(projectId, thumbnailFilePath, DataCategory.Outputs, 'image/bmp');
    logToFile(`Upload thumbnail successfully ${thumbnailObjectKey}`, LogLevel.Info);
    return thumbnailObjectKey;
  },

  // returns a promise that resolves with the uploaded content result.
  getUploadContentResult: async (
    projectId: string,
    contentToUpload: object,
    dataCategory: DataCategory,
  ): Promise<UploadContentResult> => {
    logToFile(`Start upload ${dataCategory} to oss`, LogLevel.Info);
    const uploadedContentResult = await PublishUtils.uploadContentAsFile(
      projectId,
      JSON.stringify(contentToUpload),
      getUUID(),
      'json',
      dataCategory,
    );

    logToFile(`Upload ${dataCategory} successfully ${uploadedContentResult.objectKey}`, LogLevel.Info);
    return uploadedContentResult;
  },

  dynamicContentInputsToPostVariantInputs: (dcInputs: DCInput[]): PostVariantInput[] =>
    dcInputs.map((dcInput) => ({ name: dcInput.name, value: dcInput.value!, applicable: dcInput.applicable })),

  truncateDecimals: (value: number): number => {
    const truncateLength = 4;
    let truncatedValue = value;
    // toString removes trailing zeroes (requirement 1)
    const numberParts = value.toString().split('.');

    // value is a float number
    if (numberParts.length === 2) {
      if (numberParts[1].length > truncateLength) {
        // truncate to 4 decimal places (don't round) (requirement 2)
        truncatedValue = parseFloat(`${numberParts[0]}.${numberParts[1].slice(0, truncateLength)}`);
      }
    }

    return truncatedValue;
  },

  // transform product definition inputs into Inventor inputs
  productDefinitionInputsToInventorInputs: (productDefinitionInputs: ProductDefinitionInputParameter[]): InventorInput[] =>
    productDefinitionInputs.map((productDefinitionInput) => {
      switch (productDefinitionInput.type) {
        case InputType.BOOLEAN:
          return {
            name: productDefinitionInput.name,
            value: productDefinitionInput.value.toString(),
            isProperty: false,
          };
        case InputType.TEXT:
          return {
            name: productDefinitionInput.name,
            value: productDefinitionInput.value,
            isProperty: false,
          };
        case InputType.NUMERIC:
          return {
            name: productDefinitionInput.name,
            value: PublishUtils.truncateDecimals(productDefinitionInput.value).toString(),
            isProperty: false,
          };
        default: {
          throw new Error(`Unreachable case (${productDefinitionInput.type}) error`);
        }
      }
    }),

  //transform product definition outputs into Inventor outputs
  productDefinitionOutputsToInventorOutputs: (productDefinitionOutputs: ProductDefinitionOutput[]): InventorOutput[] =>
    productDefinitionOutputs
      .filter((productDefinitionOutput) => productDefinitionOutput.type === OutputType.RFA)
      .map((productDefinitionOutput) => ({
        type: inventorOutputTypes.RFA,
        modelStates: productDefinitionOutput.options?.modelStates,
      })),

  // returns a promise that resolves with the generate output result containing files generated
  generateOutputFiles: async (productDefinition: ProductDefinition): Promise<GenerateOutputsResult> => {
    logToFile('Start generateOutputFiles', LogLevel.Info);
    const inventorInputs = PublishUtils.productDefinitionInputsToInventorInputs(productDefinition.inputs);
    const inventorOutputs = PublishUtils.productDefinitionOutputsToInventorOutputs(productDefinition.outputs);

    // Request a thumbnail image for the primary model state.
    // This will serve as the product thumbnail and match what is displayed in the UI.
    const topFolderPath = productDefinition.topLevelFolder;
    const documentFilePath = `${topFolderPath}${productDefinition.assembly}`;
    const tempModelStates: string[] = [];

    const rfaOutput = inventorOutputs.find((output) => output.type === 'RFA');
    const rfaModelStates = rfaOutput?.modelStates;

    // Use the same model states as RFA output to generate the thumbnails
    if (rfaModelStates && rfaModelStates?.length > 0) {
      tempModelStates.push(...rfaModelStates);
    } else {
      const availableModelStates = await getModelStates(documentFilePath);
      tempModelStates.push(availableModelStates[0]);
    }

    inventorOutputs.push({
      type: inventorOutputTypes.THUMBNAIL,
      modelStates: tempModelStates,
    });
    logToFile(`generating inventor outputs: ${JSON.stringify(inventorOutputs)}`, LogLevel.Info);
    const generateOutputsResult = await generateOutputs(topFolderPath, documentFilePath, inventorInputs, inventorOutputs);
    logToFile(`End generateOutputFiles: ${JSON.stringify(generateOutputsResult)}`, LogLevel.Info);
    return generateOutputsResult;
  },

  /**
   * Generates a thumbnail output. This will serve as the product thumbnail image.
   * @param productDefinition
   * @returns thumbnail output file info
   */
  generateThumbnail: async (productDefinition: ProductDefinition): Promise<InventorOutputFileInfo> => {
    logToFile('Start generateThumbnail', LogLevel.Info);
    const inventorInputs = PublishUtils.productDefinitionInputsToInventorInputs(productDefinition.inputs);

    // get the first RFA output from the productDefinition.outputs array
    const rfaOutput = productDefinition.outputs.find((output) => output.type === OutputType.RFA);

    if (!rfaOutput) {
      throw new Error('Could not find any RFA output in the product definition.');
    }

    // get the first model state from the modelStates array
    const modelState = rfaOutput.options?.modelStates && rfaOutput.options.modelStates[0];

    if (!modelState) {
      throw new Error('Could not find any model state(s) defined for the RFA output.');
    }

    const thumbnailOutput: InventorOutput = {
      type: inventorOutputTypes.THUMBNAIL,
      modelStates: [modelState],
    };

    const inventorOutputs: InventorOutput[] = [thumbnailOutput];

    const topFolderPath = productDefinition.topLevelFolder;
    const documentFilePath = `${topFolderPath}${productDefinition.assembly}`;
    const generateOutputsResult = await generateOutputs(topFolderPath, documentFilePath, inventorInputs, inventorOutputs);

    if (!generateOutputsResult.success) {
      throw new Error(`Generate Outputs failed: ${generateOutputsResult.report}`);
    }

    if (!generateOutputsResult.outputFiles) {
      throw new Error('Output files is empty or undefined.');
    }

    if (generateOutputsResult.outputFiles.length > 1) {
      // NOTE: here the assumption is that generateOutputs() must return a single
      // output file for the requested Thumbnail output.
      throw new Error('Generate Outputs returned more than one Output files.');
    }

    // get the first OutputFile from OutputFiles array
    const thumbnailFileInfo = generateOutputsResult.outputFiles[0];

    logToFile(`End generateThumbnail: ${thumbnailFileInfo}`, LogLevel.Info);
    return thumbnailFileInfo;
  },
};

export default PublishUtils;
