import React, { ReactNode, createContext } from 'react';
import { DataStore } from './dataStore';

const DataContext = createContext<DataStore>({} as DataStore);

interface DataProviderProps {
  children: ReactNode;
  store: DataStore;
}

export const DataProvider: React.FC<DataProviderProps> = ({ children, store }) => (
  <DataContext.Provider value={store}>{children}</DataContext.Provider>
);

export default DataContext;
