import { DCInput } from '@adsk/offsite-dc-sdk';
import { TabContext, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { FormLayoutTab, FormRules } from 'mid-types';
import { ForgeValidationError } from 'mid-utils';
import { ProductCustomizationFormWithoutTabs } from './ProductCustomizationFormWithoutTabs';
import { MIDEmptyState } from '@mid-react-common/common';
import text from '../../../addins.text.json';
import { BlocklyFormTabList } from '../ProductCustomization.styles';

interface ProductCustomizationFormWithTabsProps {
  formLayoutRules?: FormRules;
  inputs: FormLayoutTab[];
  inputsError?: ForgeValidationError;
  isProductConfigurable?: boolean;
  inactive?: boolean;
  error?: Error;
  tabValue: number;
  handleInputUpdate: (payload: DCInput) => Promise<void>;
  setIsFormDataValid?: (isFormDataValid: boolean) => void;
  handleTabChange: (_event: React.SyntheticEvent, newValue: number) => void;
}

export const ProductCustomizationFormWithTabs: React.FC<ProductCustomizationFormWithTabsProps> = ({
  formLayoutRules,
  inputs,
  tabValue,
  inputsError,
  inactive,
  error,
  isProductConfigurable,
  handleInputUpdate,
  handleTabChange,
  setIsFormDataValid,
}) => {
  const totalInputsInAllTabs =
    formLayoutRules && formLayoutRules.tabs && formLayoutRules.tabs.length > 0
      ? formLayoutRules.tabs.reduce((prevCount, currentTab) => {
          prevCount += currentTab.inputs?.length || 0;
          return prevCount;
        }, 0)
      : 0;
  return totalInputsInAllTabs > 0 ? (
    // Form Layout has tabs defined
    formLayoutRules?.tabs && formLayoutRules.tabs.length > 0 && formLayoutRules.tabs.length > tabValue && (
      <TabContext value={tabValue.toString()}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <BlocklyFormTabList onChange={handleTabChange} variant="scrollable" scrollButtons>
            {formLayoutRules.tabs.map((tab, index) => (
              <Tab label={tab.tabName.toUpperCase()} value={index.toString()} key={`${tab.tabId}-tab`} wrapped />
            ))}
          </BlocklyFormTabList>
        </Box>
        {inputs.map((input, index) => (
          <TabPanel value={index.toString()} key={`${input.tabId}-tab-panel`} sx={{ padding: 0 }}>
            {input.inputs.length > 0 ? (
              <ProductCustomizationFormWithoutTabs
                inputs={input.inputs}
                inputsError={inputsError}
                inactive={inactive}
                isProductConfigurable={isProductConfigurable}
                error={error}
                handleInputUpdate={handleInputUpdate}
                setIsFormDataValid={setIsFormDataValid}
              />
            ) : (
              <MIDEmptyState description={text.tabIsEmpty} hideTitle />
            )}
          </TabPanel>
        ))}
      </TabContext>
    )
  ) : (
    <MIDEmptyState title={text.formIsEmpty} warning={isProductConfigurable ? text.formMustIncludeOneInput : undefined} />
  );
};
