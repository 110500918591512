import { DCInput, Variant } from '@adsk/offsite-dc-sdk';
import { DATETIME_FORMAT, NOTIFICATION_STATUSES, ShowNotificationProps, StateSetter } from '@mid-react-common/common';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { GridColDef, GridRowSelectionModel, GridValidRowModel } from '@weave-mui/data-grid';
import { format } from 'date-fns';
import {
  isRfaOutputFailed,
  isRfaOutputPending,
  isVariantOutputSuccessfulRfaOutput,
  isVariantRfaOutput,
  applyFormRulesToInputs,
} from 'mid-utils';
import { useCallback, useEffect, useState } from 'react';
import { VariantFormState, VariantFormStates } from '../../interfaces/typings';
import text from '../../revit-components.text.json';
import { commonText } from '@mid-react-common/common';

const DATA_GRID_COLUMN_WIDTH = 150;
const DATA_GRID_GENERATED_ON_COLUMN_WIDTH = 170;

interface UseGeneratedVariantsDataGridProps {
  cachedVariantsList: Variant[];
  productReleaseInputs: DCInput[];
  reFetchCachedVariants: boolean;
  selectedCachedVariant: Variant | null;
  setThumbnail: (thumbnail: string | undefined) => void;
  setSelectedCachedVariant: StateSetter<Variant | null>;
  setVariantFormState: StateSetter<VariantFormStates>;
  updateConfigurableProductInputs: (inputs: DCInput[]) => void;
  setSelectedRepresentation: StateSetter<string | undefined>;
  showNotification: (props: ShowNotificationProps) => void;
  formRules?: string;
}

interface UseGeneratedVariantsDataGridState {
  dataGridColumns: GridColDef<GridValidRowModel>[];
  dataGridRows: GridValidRowModel[];
  rowSelectionModel: GridRowSelectionModel;
  onRowSelectionModelChange: (rowSelectionModel: GridRowSelectionModel) => void;
}

export const useGeneratedVariantsDataGrid = ({
  cachedVariantsList,
  productReleaseInputs,
  setThumbnail,
  reFetchCachedVariants,
  selectedCachedVariant,
  updateConfigurableProductInputs,
  setSelectedCachedVariant,
  setVariantFormState,
  setSelectedRepresentation,
  showNotification,
  formRules,
}: UseGeneratedVariantsDataGridProps): UseGeneratedVariantsDataGridState => {
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [failedNotified, setFailedNotified] = useState(false);

  // If we have form rules, we use those to determine which inputs to display and their labels in the DataGrid
  const inputsToDisplay = applyFormRulesToInputs({ productReleaseInputs, formRules });

  const dataGridColumns: GridColDef<GridValidRowModel>[] = inputsToDisplay.map((input) => ({
    field: input.name,
    headerName: input.label || input.name,
    width: DATA_GRID_COLUMN_WIDTH,
    type: 'string',
  }));

  // add a custom column with the variant generation date
  dataGridColumns.unshift({
    field: 'createdAt',
    headerName: text.generatedOn,
    width: DATA_GRID_GENERATED_ON_COLUMN_WIDTH,
    type: 'string',
    renderCell: (params) => (
      <>
        {params.row.isRfaPending ? (
          <Typography>
            <CircularProgress size={10} /> {text.generating}
          </Typography>
        ) : (
          params.value
        )}
      </>
    ),
  });

  const dataGridRows: GridValidRowModel[] = cachedVariantsList.map((variant) => ({
    id: variant.variantId,
    isRfaPending: isRfaOutputPending(variant),
    createdAt: isRfaOutputFailed(variant) ? text.failedCreatedAt : format(new Date(variant.createdAt), DATETIME_FORMAT),
    ...variant.inputs.reduce(
      (inputRow, { name, value, applicable }) => ({
        ...inputRow,
        [name]: applicable === false ? commonText.notApplicableLabel : value,
      }),
      {},
    ),
  }));

  const handleMultipleInputAndRepresentationUpdate = useCallback(
    (inputsToUpdate: DCInput[], representation: string | undefined) => {
      updateConfigurableProductInputs(inputsToUpdate);
      if (representation) {
        setSelectedRepresentation(representation);
      }
      setVariantFormState(VariantFormState.VARIANT_GENERATED);
    },
    [updateConfigurableProductInputs, setSelectedRepresentation, setVariantFormState],
  );

  const updateProductInputs = useCallback(
    (rowSelectionModel: GridRowSelectionModel) => {
      rowSelectionModel.forEach((rowId) => {
        const selectedVariant = cachedVariantsList.find((variant) => variant.variantId === rowId);

        if (selectedVariant && selectedVariant.variantId !== selectedCachedVariant?.variantId) {
          const updatedInputs = selectedVariant.inputs.map((input, index) => {
            const updatedInput: DCInput = { ...productReleaseInputs[index] };
            updatedInput.value = input.value;
            return updatedInput;
          });

          const rfaOutput = selectedVariant.outputs.find(isVariantOutputSuccessfulRfaOutput || isRfaOutputPending);
          handleMultipleInputAndRepresentationUpdate(updatedInputs, rfaOutput?.modelState);
          setThumbnail(selectedVariant.thumbnail);
        }
      });
    },
    [
      cachedVariantsList,
      selectedCachedVariant?.variantId,
      handleMultipleInputAndRepresentationUpdate,
      setThumbnail,
      productReleaseInputs,
    ],
  );

  const onRowSelectionModelChange = (rowSelectionModel: GridRowSelectionModel) => {
    if (!dataGridRows.length) {
      return;
    }
    updateProductInputs(rowSelectionModel);
    setRowSelectionModel(rowSelectionModel);

    const cachedVariant = cachedVariantsList.find((cachedVariant) => cachedVariant.variantId === rowSelectionModel.at(0));

    setSelectedCachedVariant(cachedVariant || null);

    // Disable insert button if variant is generating
    if (cachedVariant && isRfaOutputPending(cachedVariant)) {
      setVariantFormState(VariantFormState.VARIANT_RFA_OUTPUT_PENDING);
    } else {
      setVariantFormState(VariantFormState.EXISTING_VARIANT_SELECTED);
    }
  };

  // Automatically select the cached variant table
  useEffect(() => {
    // if CachedVariantsList is loading wait for it to finish
    if (reFetchCachedVariants) {
      return;
    }
    // if there is no selectedCachedVariant, select the earliest one (the last in the list)
    if (!selectedCachedVariant) {
      const earliestCachedVariant = cachedVariantsList.at(-1) || null;
      setSelectedCachedVariant(earliestCachedVariant);
      return;
    }

    const shouldSetSelectedCachedVariant = selectedCachedVariant.variantId !== rowSelectionModel.at(0);

    if (shouldSetSelectedCachedVariant) {
      const newRowSelectionModel: GridRowSelectionModel = [selectedCachedVariant.variantId];
      setRowSelectionModel(newRowSelectionModel);
      updateProductInputs(newRowSelectionModel);
      if (isRfaOutputPending(selectedCachedVariant)) {
        setVariantFormState(VariantFormState.VARIANT_RFA_OUTPUT_PENDING);
      } else {
        setVariantFormState(VariantFormState.EXISTING_VARIANT_SELECTED);
      }
    }
  }, [
    cachedVariantsList,
    reFetchCachedVariants,
    rowSelectionModel,
    selectedCachedVariant,
    setSelectedCachedVariant,
    setVariantFormState,
    updateProductInputs,
  ]);

  useEffect(() => {
    // if CachedVariantsList is loading wait for it to finish
    if (reFetchCachedVariants) {
      return;
    }

    // update the selectedCachedVariant when the status of the RFA output changes from PENDING to SUCCESSFUL
    if (selectedCachedVariant && isRfaOutputPending(selectedCachedVariant)) {
      const cachedVariant = cachedVariantsList.find(
        (cachedVariant) => cachedVariant.variantId === selectedCachedVariant.variantId,
      );
      const cachedVariantRfaOutput = cachedVariant?.outputs.filter((output) => isVariantRfaOutput(output));
      const selectedVariantRfaOutput = selectedCachedVariant.outputs.filter((output) => isVariantRfaOutput(output));

      if (
        cachedVariant &&
        cachedVariantRfaOutput &&
        // The selected variant could has RFA output still pending (different representation being generated)
        // but the cached variant still has only the previous RFA representation
        cachedVariantRfaOutput.length === selectedVariantRfaOutput.length &&
        !isRfaOutputPending(cachedVariant)
      ) {
        if (isRfaOutputFailed(cachedVariant) && !failedNotified) {
          showNotification({ message: text.failGenerate, severity: NOTIFICATION_STATUSES.ERROR });
          setFailedNotified(true);
        } else {
          setSelectedCachedVariant(cachedVariant);
          setThumbnail(cachedVariant.thumbnail);
        }
        setVariantFormState(VariantFormState.EXISTING_VARIANT_SELECTED);
      }
    }
  }, [
    cachedVariantsList,
    reFetchCachedVariants,
    selectedCachedVariant,
    failedNotified,
    setSelectedCachedVariant,
    setVariantFormState,
    setThumbnail,
    showNotification,
    updateProductInputs,
    setRowSelectionModel,
  ]);

  return {
    dataGridColumns,
    dataGridRows,
    rowSelectionModel,
    onRowSelectionModelChange,
  };
};
