import { DynamicContent } from '@adsk/offsite-dc-sdk';
import {
  ModalContext,
  ModalState,
  NOTIFICATION_STATUSES,
  NotificationContext,
  initialModalState,
  useCancellablePromise,
} from '@mid-react-common/common';
import { getForgeApiServiceInstance } from 'mid-api-services';
import { BIM360FolderCreationError, logError } from 'mid-utils';
import { ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import text from '../../addins.text.json';
import { TreeItem } from '../MIDTree/MIDTree.types';
import CreateNewFolderModal from './CreateNewFolderModal';
import { filterProductsByParentFolder, getEmptyFolderDataView, getEmptyProductDataView } from './ProductFolderBrowser.utils';
import { difference, pull } from 'lodash';
import { getAllChildrenToCollapse, searchItemToCollapse } from './useProductFolderBrowser.utils';

export interface UseProductFolderBrowserProps {
  projectId: string | null;
  rootFolders: TreeItem[];
  rootFoldersLoading: boolean;
  hasErrorFetchingFolders: boolean;
  selectedFolderTreeElement?: TreeItem;
  products: DynamicContent[] | undefined;
  incomingAccBridgeProducts: DynamicContent[] | undefined;
  productsLoading: boolean;
  productsError: Error | null;
  onProductClick?: (contentId: string | undefined) => void;
  onProductDoubleClick?: (product: DynamicContent) => void;
  initialExpandedIds?: string[];
}
interface UseProductFolderBrowserState {
  emptyFolderDataView: ReactNode;
  emptyProductDataView: ReactNode;
  productsInSelectedFolder: DynamicContent[] | null;
  handleProductClick: (contentId: string | undefined) => () => void;
  handleNewFolderClick: () => void;
  handleProductDoubleClick: (product: DynamicContent) => () => void;
  newFolderTreeNodeCreated: TreeItem | undefined;
  handleNodeToggle?: (nodeIds: string[], treeItems: TreeItem[]) => Promise<void>;
  expandedFoldersIds: string[];
}

const confirmButtonLabel = text.buttonCreateFolder;
const cancelButtonLabel = text.buttonCancel;
const createNewFolderModalState: ModalState = {
  ...initialModalState,
  confirmButtonLabel,
  cancelButtonLabel,
};

export const useProductFolderBrowser = ({
  projectId,
  rootFolders,
  rootFoldersLoading,
  hasErrorFetchingFolders,
  selectedFolderTreeElement,
  products,
  incomingAccBridgeProducts,
  productsLoading,
  productsError,
  onProductClick,
  onProductDoubleClick,
  initialExpandedIds,
}: UseProductFolderBrowserProps): UseProductFolderBrowserState => {
  const { setModalState } = useContext(ModalContext);
  const { showNotification } = useContext(NotificationContext);
  const cancellablePromise = useCancellablePromise();
  const [expandedFoldersIds, setExpandedFoldersIds] = useState<string[]>(initialExpandedIds || []);

  useEffect(() => {
    if (initialExpandedIds) {
      setExpandedFoldersIds(initialExpandedIds);
    }
  }, [initialExpandedIds]);

  const emptyFolderDataView: ReactNode = getEmptyFolderDataView(
    projectId,
    rootFolders,
    rootFoldersLoading,
    hasErrorFetchingFolders,
  );
  const selectedFolderUrn: string | undefined = selectedFolderTreeElement?.id;
  const selectedFolderLabel = selectedFolderTreeElement?.label;
  const nonAccBridgePoductsInSelectedFolder: DynamicContent[] | null = filterProductsByParentFolder(
    products,
    selectedFolderUrn,
  );

  // From the UI, we cannot publish into an incoming bridge folder
  // so we cannot have both bridge & non-bridge products in the same folder.
  // However, we still merge the lists, as API first users
  // can publish into an incoming bridge folder
  const allAccBridgeAndNonBridgeProducts = [
    ...(nonAccBridgePoductsInSelectedFolder ?? []),
    ...(incomingAccBridgeProducts ?? []),
  ];

  const emptyProductDataView: ReactNode = getEmptyProductDataView(
    productsLoading,
    productsError,
    selectedFolderUrn,
    allAccBridgeAndNonBridgeProducts,
  );
  const [newFolderName, setNewFolderName] = useState<string | undefined>();
  const [newFolderTreeNodeCreated, setNewFolderTreeNodeCreated] = useState<TreeItem | undefined>();
  const [isCreatingFolder, setIsCreatingFolder] = useState(false);

  const _handleCreateNewFolder = useCallback(async () => {
    if (!projectId) {
      return;
    }

    try {
      setIsCreatingFolder(true);
      if (!newFolderName || !selectedFolderUrn) {
        throw new BIM360FolderCreationError(text.missingRequiredParameterCreateNewFolder, {
          newFolderName,
          selectedFolderUrn,
        });
      }
      const response = await cancellablePromise(
        getForgeApiServiceInstance().createNewFolder(projectId, selectedFolderUrn, newFolderName),
      );
      const newFolderTreeNode: TreeItem = {
        id: response.urn,
        parentId: response.parentUrn,
        label: response.title,
        value: response.urn,
        isExpandable: true,
        children: [],
      };
      setNewFolderTreeNodeCreated(newFolderTreeNode);
      setNewFolderName(undefined);
      showNotification({
        message: text.successCreatingNewFolder,
        severity: NOTIFICATION_STATUSES.SUCCESS,
      });
    } catch (error) {
      logError(error);
      showNotification({
        message: text.errorCreatingNewFolder,
        severity: NOTIFICATION_STATUSES.ERROR,
      });
    } finally {
      setIsCreatingFolder(false);
    }
  }, [newFolderName, projectId, selectedFolderUrn, showNotification, cancellablePromise]);

  const newFolderMessageCallback = useCallback(
    () => (
      <CreateNewFolderModal
        newFolderName={newFolderName}
        setNewFolderName={setNewFolderName}
        selectedFolderLabel={selectedFolderLabel}
        isCreatingFolder={isCreatingFolder}
      />
    ),
    [newFolderName, setNewFolderName, selectedFolderLabel, isCreatingFolder],
  );

  const handleNewFolderClick = () => {
    setModalState({
      ...createNewFolderModalState,
      isOpen: true,
      message: newFolderMessageCallback(),
      disableConfirmButton: !newFolderName,
      onConfirmCallback: _handleCreateNewFolder,
      isConfirmCallbackAsync: true,
    });
  };

  useEffect(() => {
    if (typeof newFolderName !== 'undefined') {
      setModalState((prevState) => ({
        ...prevState,
        message: newFolderMessageCallback(),
        disableConfirmButton: !newFolderName,
        onConfirmCallback: _handleCreateNewFolder,
      }));
    }
  }, [newFolderName, _handleCreateNewFolder, newFolderMessageCallback, setModalState]);

  const handleProductClick = (contentId: string | undefined) => () => {
    if (onProductClick) {
      onProductClick(contentId);
    }
  };

  const handleProductDoubleClick = (product: DynamicContent) => () => {
    if (onProductDoubleClick) {
      onProductDoubleClick(product);
    }
  };

  const handleNodeToggle = async (nodeIds: string[], treeItems: TreeItem[]) => {
    // if the number of node ids coming from the TreeView is less than the number of currently expanded folders ids
    // it means that the user has collapsed some node. If that node has some expanded children and/or those expanded
    // children have expanded children too, we have to collapse them all, which means that we have to remove their
    // IDs from the 'nodeIds' array
    if (nodeIds.length < expandedFoldersIds.length) {
      // find the item that user has collapsed by getting a diff between the currently expanded nodes and what's coming
      // from the TreeView MUI component
      const collapsedItemId = difference(expandedFoldersIds, nodeIds).at(0);

      // there always be the first node (Project Files), which is the starting point for the search
      const itemToCollapse = searchItemToCollapse(treeItems.at(0), collapsedItemId);

      if (itemToCollapse) {
        // find the item in the tree structure (can be improved with a hash map if needed)
        const childrenToCollapse = getAllChildrenToCollapse(itemToCollapse);

        // remove all the children that have to be collapsed from the nodeIds
        nodeIds = pull(nodeIds, ...childrenToCollapse);
      }
    }

    // update the expanded nodes list
    setExpandedFoldersIds(nodeIds);
  };

  return {
    emptyFolderDataView,
    emptyProductDataView,
    productsInSelectedFolder: allAccBridgeAndNonBridgeProducts,
    handleProductClick,
    handleNewFolderClick,
    handleProductDoubleClick,
    newFolderTreeNodeCreated,
    expandedFoldersIds,
    handleNodeToggle,
  };
};
