import {
  DCBomOutput,
  DCDwgOutput,
  DCGlbOutput,
  DCIdwOutput,
  DCStepOutput,
  DCSatOutput,
  DCStlOutput,
  DCInput,
  DCInputBooleanType,
  DCInputNumericType,
  DCInputTextType,
  DCOutput,
  DCPdfOutput,
  DCRfaOutput,
  InputType,
  OutputStatus,
  OutputType,
  OutputTypes,
  PostVariantDWGOutputType,
  PostVariantIDWOutputType,
  PostVariantOutput,
  PostVariantPDFOutputType,
  VariantInput,
  VariantNumericInput,
  VariantOutput,
  VariantRfaOutput,
  VariantTextInput,
  VariantThumbnailOutput,
  DCIamOutput,
} from '@adsk/offsite-dc-sdk';
import isFinite from 'lodash/isFinite';
import {
  DCOutputAggregatedBom,
  DCOutputWithVirtualTypes,
  FormLayoutGroup,
  FormRulesGroup,
  FormRulesInput,
  OutputTypeWithVirtualTypes,
} from 'mid-types';

export function isNumericInput(input: DCInput): input is DCInputNumericType {
  return input.type === InputType.NUMERIC;
}

export function isTextInput(input: DCInput): input is DCInputTextType {
  return input.type === InputType.TEXT;
}

export function isBooleanInput(input: DCInput): input is DCInputBooleanType {
  return input.type === InputType.BOOLEAN;
}

export function isRejected(input: PromiseSettledResult<unknown>): input is PromiseRejectedResult {
  return input.status === 'rejected';
}

export function isFulfilled<T>(input: PromiseSettledResult<T>): input is PromiseFulfilledResult<T> {
  return input.status === 'fulfilled';
}

export function isNumericVariantInput(input: VariantInput): input is VariantNumericInput {
  return input.type === InputType.NUMERIC;
}

export function isTextVariantInput(input: VariantInput): input is VariantTextInput {
  return input.type === InputType.TEXT;
}

export function isOutputValid(outputType: string): outputType is OutputTypes {
  return outputType in OutputType;
}

export const isVariantRfaOutput = (output: VariantOutput): output is VariantRfaOutput => output.type === OutputType.RFA;

export function isVariantOutputSuccessfulRfaOutput(variantOutput: VariantOutput): variantOutput is VariantRfaOutput {
  return isVariantRfaOutput(variantOutput) && variantOutput.status === OutputStatus.SUCCESS;
}

export function isPostVariantOutputDrawing(
  output: PostVariantOutput,
): output is PostVariantDWGOutputType | PostVariantIDWOutputType | PostVariantPDFOutputType {
  return output.type === OutputType.DWG || output.type === OutputType.IDW || output.type === OutputType.PDF;
}

export const isDCRfaOutput = (output: DCOutput): output is DCRfaOutput => output.type === OutputType.RFA;

export const isVariantThumbnailOutput = (output: VariantOutput): output is VariantThumbnailOutput =>
  output.type === OutputType.THUMBNAIL;

export const isValidNumber = (value: any): value is number => isFinite(value);

export const isDrawingTemplateBasedOutput = (
  output: DCOutputWithVirtualTypes,
): output is DCDwgOutput | DCIdwOutput | DCPdfOutput =>
  output.type === OutputType.DWG || output.type === OutputType.IDW || output.type === OutputTypeWithVirtualTypes.PDF;

export const isBomBasedOutput = (output: DCOutputWithVirtualTypes): output is DCBomOutput | DCOutputAggregatedBom =>
  OutputTypeWithVirtualTypes.BOM === output.type || OutputTypeWithVirtualTypes.BOMAGGREGATED === output.type;

export const isNeutralFormatBasedOutput = (
  output: DCOutputWithVirtualTypes,
): output is DCStepOutput | DCSatOutput | DCGlbOutput | DCStlOutput =>
  OutputTypeWithVirtualTypes.STEP === output.type ||
  OutputTypeWithVirtualTypes.SAT === output.type ||
  OutputTypeWithVirtualTypes.GLB === output.type ||
  OutputTypeWithVirtualTypes.STL === output.type;

export const isModelBasedOutput = (output: DCOutputWithVirtualTypes): output is DCIamOutput =>
  OutputTypeWithVirtualTypes.IAM === output.type;

export const isModelStateBasedOutput = (
  output: DCOutputWithVirtualTypes,
): output is DCBomOutput | DCStepOutput | DCOutputAggregatedBom | DCSatOutput | DCGlbOutput | DCStlOutput =>
  OutputTypeWithVirtualTypes.BOM === output.type ||
  OutputTypeWithVirtualTypes.BOMAGGREGATED === output.type ||
  OutputTypeWithVirtualTypes.STEP === output.type ||
  OutputTypeWithVirtualTypes.SAT === output.type ||
  OutputTypeWithVirtualTypes.GLB === output.type ||
  OutputTypeWithVirtualTypes.STL === output.type;

export const isFormRulesGroup = (input: FormRulesInput | FormRulesGroup): input is FormRulesGroup =>
  'groupName' in input && 'openByDefault' in input && 'inputs' in input;

export const isDCInput = (input: DCInput | FormLayoutGroup): input is DCInput =>
  'type' in input && Object.values(InputType).includes(input.type);
export const isFormLayoutGroup = (input: DCInput | FormLayoutGroup): input is FormLayoutGroup => 'groupName' in input;
