import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import { styled } from '@mui/material/styles';

export const HeaderWrapper = styled(AppBar)`
  position: relative;
  padding: ${({ theme }) => `${theme.var.paddingBase / 2}px`} ${({ theme }) => `${theme.var.paddingBase * 1.5}px`};
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: none;
  height: ${({ theme }) => `${theme.var.midefHeaderWithTabsHeight}px`};
`;

export const HeaderTabsWrapper = styled(Tabs)`
  min-height: auto;
  height: ${({ theme }) => `${theme.var.midefHeaderWithTabsHeight}px`};
  padding-top: ${({ theme }) => `${theme.var.paddingBase}px`};
`;
