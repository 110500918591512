import * as b64Buffer from 'base64-arraybuffer';
import { EnvError, ExtractZipFileError, FileError, FileUrlError, logError } from 'mid-utils';
import { Base64Info, FileFilter } from '../interfaces/fileSystem';
import text from '../mid-addin-lib.text.json';
import browserApiService from '../services/browserApiService';
import { logToFile } from './tools';
import { LogLevel } from '../interfaces/log';
import { BrowserApiServiceResult } from '../interfaces/browserApi';

/**
 *
 * @returns selected folder path
 */
export const selectFolder = async (topFolder?: string): Promise<string> =>
  (await browserApiService.selectFolder(topFolder ?? '')) ?? '';

/**
 * @returns selected files path
 */
export const selectFile = async (filter: FileFilter[], multiSelect?: boolean, topFolder?: string): Promise<string[]> => {
  const fileFilter = JSON.stringify(filter);
  const filePaths = await browserApiService.selectFile(topFolder ?? '', fileFilter, multiSelect ?? false);
  return filePaths;
};

/**
 * @param filePath The path to the file on the local filesystem
 * @returns an encoded base64 string representation of the file
 */
export const fileToBase64String = async (filePath: string): Promise<{ name: string; base64: string }> => {
  if (!browserApiService.fileToBase64String) {
    throw new EnvError('fileToBase64String is not implemented in hostApi');
  }

  let result: BrowserApiServiceResult<Base64Info> = {
    value: null,
    errorMessage: null,
  };
  try {
    result = await browserApiService.fileToBase64String(filePath);
  } catch (error) {
    logToFile(`File size for ${filePath} is too large. Error converting file to base64 string ${error}`, LogLevel.Fatal);
  }
  if (result.value === null) {
    throw new FileError(text.failedToReadFile, {
      error: Error(result.errorMessage!),
      filePath,
    });
  }

  return result.value;
};

export const toFileObject = async (file: string): Promise<File> => {
  const base64 = await fileToBase64String(file);
  return new File([b64Buffer.decode(base64.base64)], base64.name);
};

export const compressFolder = async (folderPath: string): Promise<string> => {
  const result = await browserApiService.compressFolder(folderPath);
  if (result.value === null) {
    throw new Error(`${result.errorMessage}`);
  }

  return result.value;
};

export const extractZipFileToFolder = async (zipFilePath: string, targetFolderPath: string): Promise<string> => {
  const result = await browserApiService.extractZipFileToFolder(zipFilePath, targetFolderPath);

  if (result.value === null) {
    throw new ExtractZipFileError(result.errorMessage!, { filePath: zipFilePath });
  }

  return result.value;
};

export const downloadFileFromUrl = async (url: string, fileName?: string): Promise<string> => {
  const result = fileName
    ? await browserApiService.downloadFileFromUrlWithName(url, fileName)
    : await browserApiService.downloadFileFromUrl(url);

  if (result.value === null) {
    throw new FileUrlError(`${text.failedToDownloadFile} ${url}`, {
      error: Error(result.errorMessage!),
      url,
    });
  }

  return result.value;
};

export const urlContentToBase64String = async (url: string): Promise<{ name: string; base64: string }> => {
  if (!browserApiService.downloadFileFromUrl) {
    throw new EnvError('downloadFileFromUrl is not implemented in hostApi');
  }

  if (!browserApiService.fileToBase64String) {
    throw new EnvError('fileToBase64String is not implemented in hostApi');
  }

  const downloadResult = await browserApiService.downloadFileFromUrl(url);
  if (downloadResult.value === null) {
    throw new FileUrlError(text.failedToDownloadFile, {
      error: Error(downloadResult.errorMessage!),
      url,
    });
  }

  const result = await browserApiService.fileToBase64String(downloadResult.value);
  if (result.value === null) {
    throw new FileError(text.failedToReadFile, {
      error: Error(result.errorMessage!),
      filePath: downloadResult.value,
    });
  }

  return result.value;
};

export const deleteFile = async (filePath: string): Promise<boolean> => {
  const result = await browserApiService.deleteFile(filePath);
  if (!result.value) {
    logError(`${result.errorMessage}`);
    return false;
  }
  return true;
};

export const checkAndGenerateThumbnailInBase64 = async (
  filePath: string,
  documentPath: string,
): Promise<{ name: string; base64: string }> => {
  if (!browserApiService.checkAndGenerateThumbnailInBase64) {
    throw new EnvError('checkAndGenerateThumbnailInBase64 is not implemented in hostApi');
  }
  const result = await browserApiService.checkAndGenerateThumbnailInBase64(filePath, documentPath);
  if (result.value === null) {
    throw new FileError(text.failedToReadFile, {
      error: Error(result.errorMessage!),
      filePath,
    });
  }
  return result.value;
};

/**
 * @returns get the list drawing files path from the top Folder
 */
export const getDrawingFiles = async (topFolder: string): Promise<string[]> => {
  const filePaths = await browserApiService.getDrawingFiles(topFolder);
  return filePaths;
};
