import { ReleaseStatus } from '@adsk/offsite-dc-sdk';
import { AboutInfoMenu, CustomTab, LeftSection, RightSection } from '@mid-react-common/common';
import { VariantFormState } from '@mid-react-common/revit-components';
import ChangeRelease from 'components/ChangeRelease/ChangeRelease';
import DataContext from 'context/Data.context';
import TabsContext from 'context/Tabs/Tabs.context';
import { ConfigureAndSelectTabs } from 'context/Tabs/tabsStore';
import React, { useContext } from 'react';
import { testIds } from '../../global/dataTestIds';
import text from '../../global/mid-edit-form.text.json';
import { HeaderTabsWrapper, HeaderWrapper } from './HeaderWithTabs.styles';
import { useAddinVersion } from '@mid-react-common/addins';

const HeaderWithTabs: React.FC = () => {
  const { selectedTab, setSelectedTab } = useContext(TabsContext);
  const {
    currentProductRelease,
    variantFormState,
    nonObsoleteProductReleasesList,
    handleProductReleaseChange,
    setReFetchCachedVariants,
  } = useContext(DataContext);

  const { addinVersion, desktopApplicationVersion } = useAddinVersion();

  const handleTabChange = (_: React.SyntheticEvent, newValue: ConfigureAndSelectTabs) => {
    setSelectedTab(newValue);
  };

  return (
    <HeaderWrapper>
      <>
        <LeftSection>
          <HeaderTabsWrapper value={selectedTab} onChange={handleTabChange}>
            <CustomTab label={text.configure} data-testid={testIds.configureTab} />
            <CustomTab
              label={text.select}
              data-testid={testIds.selectTab}
              disabled={
                variantFormState === VariantFormState.GENERATING_NEW_VARIANT ||
                !currentProductRelease ||
                currentProductRelease.status === ReleaseStatus.OBSOLETE
              }
            />
          </HeaderTabsWrapper>
        </LeftSection>
        <RightSection>
          {currentProductRelease && nonObsoleteProductReleasesList && (
            <ChangeRelease
              currentProductRelease={currentProductRelease}
              nonObsoleteProductReleasesList={nonObsoleteProductReleasesList}
              handleProductReleaseChange={handleProductReleaseChange}
              variantFormState={variantFormState}
              setReFetchCachedVariants={setReFetchCachedVariants}
            />
          )}
        </RightSection>
      </>

      <AboutInfoMenu
        isTargetBlank
        webComponentVersion={import.meta.env.VITE_MIDEF_VERSION || ''}
        addinVersion={addinVersion}
        desktopApplicationVersion={desktopApplicationVersion}
      />
    </HeaderWrapper>
  );
};

export default HeaderWithTabs;
