export const DEFAULT_POLLING_INTERVAL_IN_MS = 5000;
export const InventorAddInMoniker = `IVTA`;
export const InventorWebComponentMoniker = `IVTW`;
export const RevitAddInMoniker = `RVTA`;
export const RevitWebComponentMoniker = `RVTW`;
export const MidWebAppMoniker = `MIDW`;
export const MIDEditFormAppMoniker = `MIDEF`;
export const RevitUpdateContentMoniker = `RVTUC`;
export const DATETIME_FORMAT = `MMM dd yyyy, h:mm a`;
export const adskGeneralTermsUrl = 'https://www.autodesk.com/company/terms-of-use/en/general-terms';
export const informedDesignHelpStagingUrl = 'https://help-staging.autodesk.com/view/AVRF/ENU/';
export const informedDesignHelpProductionUrl = 'https://help.autodesk.com/view/AVRF/ENU/';
export const licensingPath = '/licensing';
export const inventorInstallersPath = '/inventor';
export const revitInstallersPath = '/revit';
export const PROJECT_FILES_TITLE = 'Project Files';
export const gainsightLink = 'https://app.aptrinsic.com/mapper';
export const staticVsConfigurableProductsUrl =
  'https://help.autodesk.com/view/AVRF/ENU/?guid=AVRF_Workflows_static_vs_config_products_html';
