import { useRef, useState } from 'react';

export const configureAndSelectTabs = {
  TAB_CONFIGURE: 0,
  TAB_SELECT: 1,
} as const;
export type ConfigureAndSelectTabs = (typeof configureAndSelectTabs)[keyof typeof configureAndSelectTabs];

export interface TabsStore {
  selectedTab: ConfigureAndSelectTabs;
  setSelectedTab: (tab: ConfigureAndSelectTabs) => void;
  previouslySelectedTab: ConfigureAndSelectTabs | undefined;
}

export const useTabsStore = (): TabsStore => {
  const [selectedTab, _setSelectedTab] = useState<ConfigureAndSelectTabs>(configureAndSelectTabs.TAB_CONFIGURE);
  const ref = useRef<ConfigureAndSelectTabs | undefined>(undefined);

  const setSelectedTab = (tab: ConfigureAndSelectTabs) => {
    _setSelectedTab((previousTab) => {
      ref.current = previousTab;
      return tab;
    });
  };

  return {
    selectedTab,
    setSelectedTab,
    previouslySelectedTab: ref.current,
  };
};
