import { isValidNumber } from 'mid-utils';
import React, { ChangeEvent, FocusEvent, KeyboardEvent, useEffect, useState } from 'react';
import { ParameterCustomizationWrapper, TextFieldWrapper } from '../ProductCustomization.styles';
import text from '../../../addins.text.json';

export interface NumericParameterCustomizationProps {
  value: number | undefined;
  readOnly?: boolean;
  min?: number;
  max?: number;
  increment?: number;
  label?: string;
  error?: boolean;
  helperText: string;
  isApplicable?: boolean;
  onNumericValueChange: (newValue: number) => Promise<void>;
  setIsFormDataValid?: (isFormValid: boolean) => void;
  isProductConfigurable?: boolean;
}

const NumericParameterCustomization: React.FC<NumericParameterCustomizationProps> = ({
  value,
  min,
  max,
  increment,
  readOnly,
  helperText,
  label,
  error,
  onNumericValueChange,
  isApplicable,
  isProductConfigurable = true,
}) => {
  const [localValue, setLocalValue] = useState<string>('');
  const [valueUpdated, setValueUpdated] = useState(false);

  // make sure that the updated value from the code runner is set to the local value
  if (valueUpdated && isValidNumber(value)) {
    if (value.toString() !== localValue) {
      setLocalValue(value.toString());
    }
    setValueUpdated(false);
  }

  // this use effect won't be called in case user manually changed the input value to something less than the min value
  // in this case, the same min value will be passed as the 'value' prop in this component, which won't trigger this
  // useEffect, as a result, the local value won't be updated
  useEffect(() => {
    setLocalValue(isValidNumber(value) ? value.toString() : '');
  }, [value]);

  const updateLocalValue = (e: ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.target.value);
  };

  const onKeyDown = async (e: KeyboardEvent<HTMLInputElement>) => {
    // trigger code runner when Enter is pressed
    if (e.key === 'Enter') {
      await onNumericValueChange(Number(localValue));
      setValueUpdated(true);
    }
  };

  const updateValue = async (e: FocusEvent<HTMLInputElement, Element>) => {
    // must wait for the value change operation executed (code runner), only then set value updated state to true
    await onNumericValueChange(Number(e.target.value));
    setValueUpdated(true);
  };

  const isDisabled = isApplicable === false || readOnly || !isProductConfigurable;
  const fieldType = isApplicable === false ? 'text' : 'number';
  const fieldValue = isApplicable === false ? text.notApplicablePlaceholder : localValue;

  return (
    <ParameterCustomizationWrapper>
      <TextFieldWrapper
        type={fieldType}
        size="small"
        value={fieldValue}
        onChange={updateLocalValue}
        label={label}
        onBlur={updateValue}
        onKeyDown={onKeyDown}
        helperText={helperText}
        disabled={isDisabled}
        error={error}
        inputProps={{ max, min, step: increment, readOnly }}
        InputLabelProps={{ tabIndex: 0 }}
      />
    </ParameterCustomizationWrapper>
  );
};

export default NumericParameterCustomization;
