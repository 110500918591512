import { ProductRelease, ReleaseStatus } from '@adsk/offsite-dc-sdk';
import { ReleaseStatusWithColorBar, nameAndRelease } from '@mid-react-common/common';
import Lock from '@mui/icons-material/Lock';
import IconButton from '@mui/material/IconButton';
import isUndefined from 'lodash/isUndefined';
import React from 'react';
import text from '../../revit-components.text.json';
import {
  LockTooltip,
  NameAndReleaseWrapper,
  NameTitle,
  ProductReleaseContainer,
  ProductReleaseTypographyWrapper,
  ProductReleasesDescription,
} from './NameAndRelease.styles';

interface NameAndReleaseProps {
  currentProductRelease: ProductRelease;
  productReleasesListLength: number | undefined;
}

const NameAndRelease: React.FC<NameAndReleaseProps> = ({ currentProductRelease, productReleasesListLength }) => (
  <NameAndReleaseWrapper>
    <NameTitle data-testid={nameAndRelease.releaseName} variant="h2">
      {currentProductRelease.name}
    </NameTitle>
    <ProductReleaseContainer data-testid={nameAndRelease.releasesAndStatus}>
      <ProductReleaseTypographyWrapper>
        {!isUndefined(productReleasesListLength) && (
          <ProductReleasesDescription className="mid-status-secondary">
            {`${currentProductRelease.release} of ${productReleasesListLength}`}
          </ProductReleasesDescription>
        )}
        <ReleaseStatusWithColorBar status={currentProductRelease.status} useSecondaryColor />
      </ProductReleaseTypographyWrapper>
      {currentProductRelease.status === ReleaseStatus.OBSOLETE && (
        <LockTooltip title={text.valuesOfObsoleteReleasesAreReadOnlyTooltip}>
          <IconButton>
            <Lock fontSize="small" />
          </IconButton>
        </LockTooltip>
      )}
    </ProductReleaseContainer>
  </NameAndReleaseWrapper>
);

export default NameAndRelease;
