import Box from '@mui/material/Box';
import { css, styled } from '@mui/material/styles';
import { DataGridPremium } from '@weave-mui/data-grid';

export const ProductDetailsTabsContainer = styled(Box)`
  display: flex;
`;

export const TabPanelContainer = styled(Box)`
  padding: ${({ theme }) => theme.var.paddingBase}px 0;
  height: 100%;
`;

export const DataGridPremiumWrapper = styled(DataGridPremium)<{ height?: number }>`
  // the custom height is needed to accommodate the loading spinner if the grid is empty
  ${(props) =>
    props.height !== undefined &&
    css(`
      height: ${props.height}px;
    `)}
  & .MuiDataGrid-columnHeaders {
    background-color: ${({ theme }) => theme.colors.surface.lightGray[10]};
    font-weight: bold;
    border-bottom: ${({ theme }) => `${theme.var.borderHeight}px solid ${theme.palette.divider}`};
  }
`;

export const RightRefreshButtonSection = styled('div')`
  margin-left: auto;
  margin-top: auto;
`;
