import { Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';

export const EditFormHeader = styled(AppBar)`
  border-bottom: 1px ${({ theme }) => `${theme.palette.divider}`} solid;
`;

export const EditFormHeaderWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.var.paddingBase}px ${theme.var.paddingBase * 2}px`};
`;

export const DropdownItemSubText = styled('span')`
  font-size: ${({ theme }) => theme.typography.subtitle2.fontSize};
  color: ${({ theme }) => theme.colors.primary.charcoal[500]};
`;

export const ProductNameTypography = styled(Typography)`
  margin: 0 ${({ theme }) => `${theme.var.marginBase}px`};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const TitleWrapper = styled('div')`
  min-width: ${({ theme }) => `${theme.var.midefTitleMinWidth}px`};
  display: flex;
  align-items: center;
`;
