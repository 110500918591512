import { DCInput, DCOutput, ProductRelease, ReleaseStatus, Variant } from '@adsk/offsite-dc-sdk';
import { ConfigurableProductProperties, StateSetter } from '@mid-react-common/common';
import {
  ConfigurableProductActionTypes,
  ConfigurableProductActions,
  VariantFormState,
  VariantFormStates,
  configurableProductReducer,
  initialConfigurableProductPropertiesMap,
} from '@mid-react-common/revit-components';
import { mergeProductReleaseInputsWithVariantInputValues, mergeVariantAndProductReleaseInputs } from '../utils';
import { ProductsUtils } from 'mid-addin-lib';
import { isDCRfaOutput, isVariantRfaOutput } from 'mid-utils';
import { useMemo, useReducer, useState } from 'react';
import { MIDSelectedRFAInstance } from 'global/selectedRFAInstance.utils';
import { DCProductUIExtension, DCRules } from 'mid-types';

export interface DataStore {
  rfaInstance: MIDSelectedRFAInstance | undefined;
  currentProductRelease: DCProductUIExtension<ProductRelease> | undefined;
  currentVariantOfInstance: Variant | undefined;
  selectedCachedVariant: Variant | null;
  configurableProductProperties: ConfigurableProductProperties;
  variantFormState: VariantFormStates;
  cachedVariantsList: Variant[];
  selectedRepresentation: string | undefined;
  reFetchCachedVariants: boolean;
  productReleasesListLength: number | undefined;
  nonObsoleteProductReleasesList: DCProductUIExtension<ProductRelease>[] | undefined;
  isFormInitializing: boolean;
  selectedVariantThumbnailVisible: boolean;
  postedVariantIdsList: string[];
  setRfaInstance: StateSetter<MIDSelectedRFAInstance | undefined>;
  setCurrentProductRelease: StateSetter<DCProductUIExtension<ProductRelease> | undefined>;
  setCurrentVariantOfInstance: StateSetter<Variant | undefined>;
  setCurrentProductReleaseOfInstance: StateSetter<DCProductUIExtension<ProductRelease> | undefined>;
  setSelectedCachedVariant: StateSetter<Variant | null>;
  updateConfigurableProductInput: (inputToUpdate: DCInput) => void;
  updateConfigurableProductInputs: (updatedInputs: DCInput[], productReleaseOutputs?: DCOutput[]) => void;
  setVariantFormState: StateSetter<VariantFormStates>;
  setCachedVariantsList: StateSetter<Variant[]>;
  setSelectedRepresentation: StateSetter<string | undefined>;
  setReFetchCachedVariants: StateSetter<boolean>;
  resetConfigurableProductProperties: () => void;
  setProductReleasesList: StateSetter<DCProductUIExtension<ProductRelease>[] | undefined>;
  setIsFormInitializing: StateSetter<boolean>;
  handleProductReleaseChange: (productRelease: DCProductUIExtension<ProductRelease>) => Promise<void>;
  setSelectedVariantThumbnailVisible: StateSetter<boolean>;
  dispatchConfigurableProductUpdateAction: React.Dispatch<ConfigurableProductActions>;
  setPostedVariantIdsList: StateSetter<string[]>;
}

const useDataStore = (): DataStore => {
  const [rfaInstance, setRfaInstance] = useState<MIDSelectedRFAInstance | undefined>();
  const [currentProductRelease, setCurrentProductRelease] = useState<DCProductUIExtension<ProductRelease> | undefined>();

  // Saving ProductRelease and Variant used to create the selected instance
  // to be able to Reset the Form and ChangeRelease Dropdpown
  const [currentProductReleaseOfInstance, setCurrentProductReleaseOfInstance] = useState<
    DCProductUIExtension<ProductRelease> | undefined
  >();
  const [currentVariantOfInstance, setCurrentVariantOfInstance] = useState<Variant | undefined>();

  const [selectedCachedVariant, setSelectedCachedVariant] = useState<Variant | null>(null);

  const [productReleasesList, setProductReleasesList] = useState<DCProductUIExtension<ProductRelease>[] | undefined>();
  const nonObsoleteProductReleasesList = useMemo(
    () => productReleasesList?.filter((release) => release.status !== ReleaseStatus.OBSOLETE),
    [productReleasesList],
  );

  const [isFormInitializing, setIsFormInitializing] = useState<boolean>(true);

  const [configurableProductPropertiesMap, dispatchConfigurableProductUpdateAction] = useReducer(
    configurableProductReducer,
    initialConfigurableProductPropertiesMap,
  );
  const [variantFormState, setVariantFormState] = useState<VariantFormStates>(VariantFormState.DEFAULT_VARIANT);
  const [cachedVariantsList, setCachedVariantsList] = useState<Variant[]>([]);
  const [selectedRepresentation, setSelectedRepresentation] = useState<string | undefined>();
  const [reFetchCachedVariants, setReFetchCachedVariants] = useState(true);
  const [selectedVariantThumbnailVisible, setSelectedVariantThumbnailVisible] = useState<boolean>(true);

  const [postedVariantIdsList, setPostedVariantIdsList] = useState<string[]>([]);

  const updateConfigurableProductInput = (inputToUpdate: DCInput) => {
    dispatchConfigurableProductUpdateAction({
      type: ConfigurableProductActionTypes.UPDATE_INPUT,
      payload: inputToUpdate,
    });
  };

  const updateConfigurableProductInputs = (updatedInputs: DCInput[], productReleaseOutputs?: DCOutput[]) => {
    dispatchConfigurableProductUpdateAction({
      type: ConfigurableProductActionTypes.UPDATE_ALL_INPUTS,
      payload: {
        inputs: updatedInputs,
        outputs: productReleaseOutputs || currentProductRelease?.outputs || [],
      },
    });
  };

  const resetConfigurableProductProperties = async () => {
    if (currentProductReleaseOfInstance && currentVariantOfInstance) {
      const mergedProductReleaseVariantInputs = mergeVariantAndProductReleaseInputs(
        currentProductReleaseOfInstance.inputs,
        currentVariantOfInstance.inputs,
      );

      dispatchConfigurableProductUpdateAction({
        type: ConfigurableProductActionTypes.INITIALIZE_CONFIGURABLE_PRODUCT_PAYLOAD,
        payload: {
          inputs: mergedProductReleaseVariantInputs,
          outputs: currentProductReleaseOfInstance.outputs,
        },
      });

      const rfaOutput = currentProductReleaseOfInstance?.outputs.find(isDCRfaOutput);
      const defaultRepresentation = rfaOutput?.options?.modelStates?.at(0);
      setSelectedRepresentation(defaultRepresentation);

      resetToCurrentProductReleaseOfInstance();
    }
  };

  const resetToCurrentProductReleaseOfInstance = () => {
    if (currentProductReleaseOfInstance) {
      setCurrentProductRelease(currentProductReleaseOfInstance);
    }
  };

  const handleProductReleaseChange = async (productRelease: DCProductUIExtension<ProductRelease>) => {
    const { sourceProjectId, targetProjectId } = rfaInstance || {};
    const productReleaseRules: DCRules | undefined =
      (await ProductsUtils.checkAndDownloadProductRulesFromKey({
        tenancyId: productRelease.tenancyId,
        rulesKey: productRelease.rulesKey,
        incomingAccBridgeData: {
          sourceProjectId,
          targetProjectId,
        },
      })) || productRelease.rules;

    const configurableProductPropertiesInputValues = [...configurableProductPropertiesMap.inputs.values()];
    const mergedProductReleaseVariantInputs = mergeProductReleaseInputsWithVariantInputValues(
      productRelease.inputs,
      configurableProductPropertiesInputValues || currentVariantOfInstance?.inputs || [],
    );

    setCurrentProductRelease({ ...productRelease, rules: productReleaseRules });
    updateConfigurableProductInputs(mergedProductReleaseVariantInputs, productRelease.outputs);
    setVariantFormState(VariantFormState.EDITING_NEW_VARIANT);

    const productReleasesRFAOutputModelStates = productRelease.outputs.find(isDCRfaOutput)?.options.modelStates;
    const selectedCachedVariantRFAOutput = selectedCachedVariant?.outputs.filter(isVariantRfaOutput);

    // Changing releases, it can happens that selected representation from cached variant is not available in new release
    const availableModelStateRFAInProductRelease = selectedCachedVariantRFAOutput?.find((output) =>
      productReleasesRFAOutputModelStates?.includes(output.modelState),
    );

    if (!availableModelStateRFAInProductRelease) {
      // If the selected representation is not available in the new release, select the first one
      setSelectedRepresentation(productReleasesRFAOutputModelStates?.at(0));
    } else {
      setSelectedRepresentation(availableModelStateRFAInProductRelease.modelState);
    }

    // After change to a new Product Release, set form initializing to true in order to
    // re-trigger the code runner in useProductCustomizationFormInRevit
    setIsFormInitializing(true);
  };

  return {
    rfaInstance,
    currentProductRelease,
    currentVariantOfInstance,
    selectedCachedVariant,
    configurableProductProperties: {
      inputs: [...configurableProductPropertiesMap.inputs.values()],
      outputs: configurableProductPropertiesMap.outputs,
    },
    variantFormState,
    cachedVariantsList,
    selectedRepresentation,
    reFetchCachedVariants,
    productReleasesListLength: productReleasesList?.length,
    nonObsoleteProductReleasesList,
    isFormInitializing,
    selectedVariantThumbnailVisible,
    postedVariantIdsList,
    setRfaInstance,
    setCurrentProductRelease,
    setCurrentVariantOfInstance,
    setCurrentProductReleaseOfInstance,
    setSelectedCachedVariant,
    updateConfigurableProductInput,
    updateConfigurableProductInputs,
    setVariantFormState,
    setCachedVariantsList,
    setSelectedRepresentation,
    setReFetchCachedVariants,
    resetConfigurableProductProperties,
    setProductReleasesList,
    setIsFormInitializing,
    handleProductReleaseChange,
    setSelectedVariantThumbnailVisible,
    dispatchConfigurableProductUpdateAction,
    setPostedVariantIdsList,
  };
};

export default useDataStore;
