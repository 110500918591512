import { codeRunner, CodeRunnerResult } from '@adsk/informed-design-code-runner';
import text from './global/mid-edit-form.text.json';
import { DCInput, VariantInput } from '@adsk/offsite-dc-sdk';

const { productAndVariantTypeDoNotMatchError } = text.MIDEditForm;

export const enhanceInputsWithCodeRunnerRules = async (
  mergedProductVariantInputs: DCInput[],
  codeRunnerRuleFromProduct: string | undefined,
  showMessageDialog?: (message: string) => void,
): Promise<CodeRunnerResult> => {
  if (!codeRunnerRuleFromProduct) {
    return { result: mergedProductVariantInputs, error: null, valueUpdates: [] };
  }

  return await codeRunner({
    code: codeRunnerRuleFromProduct,
    inputs: mergedProductVariantInputs,
    printCallback: showMessageDialog,
  });
};

// [NOTE] VariantTextInput & VariantNumericInput do
// not have the "values" attribute for multi value inputs, so we need
// to get it from the corresponding Product input
export const mergeVariantAndProductReleaseInputs = (
  productReleaseInputs: DCInput[],
  variantInputs: VariantInput[],
): DCInput[] => {
  interface ProductReleaseInputsMap {
    [key: string]: DCInput;
  }
  const productReleaseInputsMap = productReleaseInputs.reduce<ProductReleaseInputsMap>(
    (productReleaseInputMap: ProductReleaseInputsMap, productReleaseInput: DCInput) => {
      productReleaseInputMap[productReleaseInput.name] = productReleaseInput;
      return productReleaseInputMap;
    },
    {},
  );

  const mergedInputs = variantInputs.map((variantInput: VariantInput) => {
    const matchedProductInput: DCInput = productReleaseInputsMap[variantInput.name];
    if (!matchedProductInput) {
      throw Error(productAndVariantTypeDoNotMatchError);
    }
    return {
      // Variant input values take precedence over product
      // inputs & will overwrite
      ...matchedProductInput,
      ...variantInput,
    };
  });

  return mergedInputs as DCInput[];
};

// Returns an array of all ProductRelease Inputs with the values from the VariantInputs given
export const mergeProductReleaseInputsWithVariantInputValues = (
  productReleaseInputs: DCInput[],
  variantInputs: VariantInput[],
): DCInput[] => {
  const VariantInputMap = variantInputs.reduce((variantInputMap: Map<string, VariantInput>, variantInput: VariantInput) => {
    variantInputMap.set(variantInput.name, variantInput);
    return variantInputMap;
  }, new Map());

  const result = productReleaseInputs.map((productReleaseInput: DCInput) => {
    const variantInputFound = VariantInputMap.get(productReleaseInput.name);
    if (variantInputFound) {
      return {
        ...productReleaseInput,
        value: variantInputFound.value,
      } as DCInput;
    }
    return productReleaseInput;
  });

  return result;
};
