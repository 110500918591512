import { DCInput } from '@adsk/offsite-dc-sdk';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import { DCInputUIExtension, FormLayoutGroup } from 'mid-types';
import { ForgeValidationError } from 'mid-utils';
import React from 'react';
import { productCustomizationTestIds } from '../../../dataTestIds';
import { AccordionContent, AccordionFullWidth, AccordionTitle } from '../ProductCustomization.styles';
import { ProductCustomizationFormInputs } from './ProductCustomizationFormInputs';
import { MIDEmptyState } from '@mid-react-common/common';
import text from '../../../addins.text.json';
interface ProductCustomizationFormGroupProps {
  formLayoutGroup: FormLayoutGroup;
  inputsError?: ForgeValidationError;
  inactive?: boolean;
  handleInputUpdate: (payload: DCInput) => Promise<void>;
  setIsFormDataValid?: (isFormDataValid: boolean) => void;
  isProductConfigurable?: boolean;
}

const { formRowItemTestIdPrefix, productCustomizationFormGroupContent } = productCustomizationTestIds;

export const ProductCustomizationFormGroup: React.FC<ProductCustomizationFormGroupProps> = ({
  formLayoutGroup,
  inputsError,
  inactive,
  handleInputUpdate,
  setIsFormDataValid,
  isProductConfigurable = true,
}) => (
  <>
    <AccordionFullWidth
      disableGutters
      elevation={0}
      square
      defaultExpanded={formLayoutGroup.openByDefault}
      data-testid={`${formRowItemTestIdPrefix}${formLayoutGroup.groupName}`}
    >
      <AccordionTitle expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h3">{formLayoutGroup.groupName}</Typography>
      </AccordionTitle>
      <AccordionContent data-testid={`${productCustomizationFormGroupContent}${formLayoutGroup.groupName}`}>
        {formLayoutGroup.inputs.length > 0 ? (
          formLayoutGroup.inputs.map((groupInput, index) => (
            <ProductCustomizationFormInputs
              currentInput={groupInput as DCInputUIExtension<DCInput>}
              inputsError={inputsError}
              inactive={inactive}
              handleInputUpdate={handleInputUpdate}
              setIsFormDataValid={setIsFormDataValid}
              key={groupInput.name + index}
              isProductConfigurable={isProductConfigurable}
            />
          ))
        ) : (
          <MIDEmptyState description={text.groupIsEmpty} hideTitle />
        )}
      </AccordionContent>
    </AccordionFullWidth>
  </>
);
