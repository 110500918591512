import { DCInput } from '@adsk/offsite-dc-sdk';
import { EMPTY_STATE_ILLUSTRATION_TYPES, FieldSetContainer, MIDEmptyState } from '@mid-react-common/common';
import Divider from '@mui/material/Divider';
import { DCInputUIExtension, FormLayoutGroup, FormLayoutTab, FormRules } from 'mid-types';
import { ForgeValidationError } from 'mid-utils';
import React from 'react';
import { productCustomizationTestIds } from '../../dataTestIds';
import {
  ConfigureProductCustomizationHeader,
  FormLoadingSpinner,
  ProductCustomizationFormWrapper,
} from './ProductCustomization.styles';
import { mapInputsToFormLayoutRules } from './ProductCustomization.utils';
import { ProductCustomizationFormWithoutTabs } from './Components/ProductCustomizationFormWithoutTabs';
import { ProductCustomizationFormWithTabs } from './Components/ProductCustomizationFormWithTabs';

const { productCustomizationForm, productCustomizationFormLoadingSpinner, productCustomizationFormHeader } =
  productCustomizationTestIds;

interface ProductCustomizationFormProps {
  inputs: DCInput[];
  inputsError?: ForgeValidationError;
  loading?: boolean;
  inactive?: boolean;
  error?: Error;
  errorDescription?: string | JSX.Element;
  formLayoutRules?: FormRules;
  isFormLoading?: boolean;
  isProductConfigurable?: boolean;
  tabValue?: number;
  handleTabChange?: (_event: React.SyntheticEvent, newValue: number) => void;
  handleInputUpdate: (payload: DCInput) => Promise<void>;
  setIsFormDataValid?: (isFormDataValid: boolean) => void;
}

const ProductCustomizationForm: React.FC<ProductCustomizationFormProps> = ({
  inputs,
  inputsError,
  inactive,
  error,
  errorDescription,
  formLayoutRules,
  isFormLoading,
  isProductConfigurable = true,
  tabValue = 0,
  handleInputUpdate,
  setIsFormDataValid,
  handleTabChange,
}) => {
  if (isFormLoading) {
    return (
      <FieldSetContainer data-testid={productCustomizationForm}>
        <FormLoadingSpinner data-testid={productCustomizationFormLoadingSpinner} />
      </FieldSetContainer>
    );
  }

  if (inputs.length > 0 && error) {
    return (
      <FieldSetContainer data-testid={productCustomizationForm} fullWidth={!!error}>
        <MIDEmptyState
          title={error.message}
          description={errorDescription}
          illustrationType={EMPTY_STATE_ILLUSTRATION_TYPES.SYSTEM_ERROR}
        />
      </FieldSetContainer>
    );
  }

  // If product is not configurable and there are not inputs, return empty form
  if (inputs.length === 0 && isProductConfigurable === false) {
    return (
      <ProductCustomizationFormWrapper>
        <FieldSetContainer data-testid={productCustomizationForm} />
      </ProductCustomizationFormWrapper>
    );
  }

  const inputsAfterRules = mapInputsToFormLayoutRules(inputs, formLayoutRules);

  return (
    <ProductCustomizationFormWrapper>
      {formLayoutRules?.formName && (
        <>
          <ConfigureProductCustomizationHeader variant="h2" data-testid={productCustomizationFormHeader}>
            {formLayoutRules?.formName}
          </ConfigureProductCustomizationHeader>
          <Divider flexItem />
        </>
      )}
      {/* Form layout has tabs defined */}
      {formLayoutRules && !formLayoutRules.inputs && formLayoutRules.tabs && handleTabChange && (
        <ProductCustomizationFormWithTabs
          formLayoutRules={formLayoutRules}
          inputs={inputsAfterRules as FormLayoutTab[]}
          inputsError={inputsError}
          error={error}
          inactive={inactive}
          isProductConfigurable={isProductConfigurable}
          tabValue={tabValue}
          handleInputUpdate={handleInputUpdate}
          setIsFormDataValid={setIsFormDataValid}
          handleTabChange={handleTabChange}
        />
      )}
      {/* Form layout only have inputs or groups defined */}
      <ProductCustomizationFormWithoutTabs
        inputs={inputsAfterRules as (DCInputUIExtension<DCInput> | FormLayoutGroup)[]}
        inputsError={inputsError}
        error={error}
        inactive={inactive}
        isProductConfigurable={isProductConfigurable}
        handleInputUpdate={handleInputUpdate}
        setIsFormDataValid={setIsFormDataValid}
      />
    </ProductCustomizationFormWrapper>
  );
};

export default ProductCustomizationForm;
