import { LogLevel } from '../interfaces/log';
import { InputRule } from '../interfaces/productDefinitions';
import browserApiService from '../services/browserApiService';
import { DCRules } from 'mid-types';

export const getAssemblyVersion = (): Promise<string> => browserApiService?.getAssemblyVersion();

export const openMidWebapp = async (): Promise<boolean> => {
  const result = await browserApiService?.openExternalUrl(await browserApiService?.getMIDWebAppUrl());
  if (!result.value) {
    throw new Error(`${result.errorMessage}`);
  }
  return result.value;
};

export const getApplicationVersionNumber = (): Promise<string> => browserApiService?.getApplicationVersionNumber();

export const saveToFile = async (content: string, fileName: string, fileExtension: string): Promise<string> => {
  const result = await browserApiService?.saveToFile(content, fileName, fileExtension);
  if (!result.value) {
    throw new Error(`${result.errorMessage}`);
  }
  return result.value;
};

export const inputsRulesToTemplateRules = (rules: InputRule[]): DCRules =>
  rules.reduce<DCRules>(
    (templateRule, rule) => (
      (templateRule[rule.key] = { code: rule.code, errorMessage: rule.errorMessage, ruleLabel: rule.label }), templateRule
    ),
    {},
  );

export const logToFile = async (message: string, logLevel: LogLevel): Promise<void> => {
  await browserApiService?.logToFile(message, logLevel);
};

export const resizeHostWindow = (width: number, height: number): Promise<void> =>
  browserApiService?.resizeWindow(width, height);

export const encodeToBase64 = (stringToEncode: string): string => {
  const encoder = new TextEncoder();
  const data = encoder.encode(stringToEncode);
  return window.btoa(String.fromCharCode(...data));
};
