export const productFolderBrowser = {
  productCell: 'product-cell',
  treeIconHigFolder: 'tree-icon-hig-folder',
  treeIconHigFolderOpen: 'tree-icon-hig-folder-open',
  treeIconLockedFolder: 'tree-icon-locked-folder',
  treeIconLockedFolderOpen: 'tree-icon-locked-folder-open',
  treeIconFolder: 'tree-icon-folder',
  treeIconFolderOpen: 'tree-icon-folder-open',
  buttonNewFolder: 'button-new-folder',
};

export const productCustomizationTestIds = {
  productCustomizationFormLoadingSpinner: 'product-customization-form-loading-spinner',
  productCustomizationFormLoadingSpinnerOverlay: 'product-customization-form-loading-spinner-overlay',
  productCustomizationForm: 'product-customization-form',
  productCustomizationFormWrapper: 'product-customization-form-wrapper',
  formRowItemTestIdPrefix: 'form-row-item-',
  insertButton: 'insert-button',
  customizeButton: 'customize-button',
  productCustomizationFormHeader: 'product-customization-form-header',
  productCustomizationFormGroupContent: 'product-customization-form-group-content',
  productCustomizationFormGroup: 'product-customization-form-group',
};
