import { ReleaseStatus, ReleaseStatusValue } from '@adsk/offsite-dc-sdk';
import { Dropdown, DropdownItem } from '@mid-react-common/addins';
import { RightButton } from '@mid-react-common/common';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import React, { useState, useEffect } from 'react';
import { productCustomizationFooter } from '../../dataTestIds';
import { VariantFormState, VariantFormStates } from '../../interfaces/typings';
import text from '../../revit-components.text.json';
import {
  ProductCustomizationFooterContainer,
  RepresentationEditButton,
  RepresentationEditButtonText,
  StyledCreateIcon,
} from './ProductCustomizationFooter.styles';

type ProductCustomizationFooterProps = {
  handleResetClick: () => void;
  handleGenerateNewVariantClick: () => void;
  handleRfaRepresentationSelection: ({ selectedItem }: { selectedItem?: DropdownItem | null }) => void;
  variantFormState: VariantFormStates;
  isFormDataValid: boolean;
  selectedRepresentationDropdownItem: DropdownItem | null;
  representationDropdownItems: DropdownItem[];
  currentProductReleaseStatus: ReleaseStatusValue | undefined;
  isProductConfigurable?: boolean;
};

const ProductCustomizationFooter: React.FC<ProductCustomizationFooterProps> = ({
  variantFormState,
  isFormDataValid,
  selectedRepresentationDropdownItem,
  representationDropdownItems,
  currentProductReleaseStatus,
  handleGenerateNewVariantClick,
  handleResetClick,
  handleRfaRepresentationSelection,
  isProductConfigurable = true,
}) => {
  const theme = useTheme();
  const [isEditingRepresentation, setIsEditingRepresentation] = useState(false);
  const editRepresentationButton = () => setIsEditingRepresentation(true);
  const closeEditRepresentation = () => setIsEditingRepresentation(false);

  useEffect(() => {
    if (representationDropdownItems.length <= 1 && isEditingRepresentation) {
      setIsEditingRepresentation(false);
    }
  }, [representationDropdownItems.length, isEditingRepresentation]);

  if (isEditingRepresentation) {
    return (
      <ProductCustomizationFooterContainer
        justifyContent="space-between"
        data-testid={productCustomizationFooter.representationContainer}
      >
        <div>
          <Dropdown
            elements={representationDropdownItems}
            label={text.representations}
            selectedItem={selectedRepresentationDropdownItem}
            onSelect={handleRfaRepresentationSelection}
            width={`${theme.var.wideInputControl}px`}
          />
          <IconButton
            size="small"
            onClick={closeEditRepresentation}
            data-testid={productCustomizationFooter.closeRepresentationIconButton}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </ProductCustomizationFooterContainer>
    );
  }

  const isRepresentationFieldShown = representationDropdownItems.length > 0 && selectedRepresentationDropdownItem;

  const isRepresentationEditButtonDisabled =
    representationDropdownItems.length <= 1 ||
    currentProductReleaseStatus === ReleaseStatus.OBSOLETE ||
    variantFormState === VariantFormState.GENERATING_NEW_VARIANT ||
    variantFormState === VariantFormState.INSERTING_VARIANT ||
    variantFormState === VariantFormState.REPLACING_VARIANT ||
    !isProductConfigurable;

  const isGenerateButtonDisabled = variantFormState !== VariantFormState.EDITING_NEW_VARIANT || !isFormDataValid;

  const isResetButtonDisabled =
    variantFormState === VariantFormState.DEFAULT_VARIANT ||
    variantFormState === VariantFormState.GENERATING_NEW_VARIANT ||
    !isProductConfigurable;

  return (
    <ProductCustomizationFooterContainer justifyContent={isRepresentationFieldShown ? 'space-between' : 'end'}>
      {isRepresentationFieldShown && (
        <RepresentationEditButton
          onClick={editRepresentationButton}
          disabled={isRepresentationEditButtonDisabled}
          data-testid={productCustomizationFooter.editRepresentationIconButton}
        >
          <RepresentationEditButtonText>{selectedRepresentationDropdownItem.value}</RepresentationEditButtonText>
          <StyledCreateIcon color={isRepresentationEditButtonDisabled ? 'disabled' : 'inherit'} />
        </RepresentationEditButton>
      )}
      <div>
        <RightButton
          variant="text"
          onClick={handleResetClick}
          disabled={isResetButtonDisabled}
          data-testid={productCustomizationFooter.resetButton}
        >
          {text.revitReset}
        </RightButton>
        <RightButton
          variant="text"
          onClick={handleGenerateNewVariantClick}
          disabled={isGenerateButtonDisabled}
          data-testid={productCustomizationFooter.generateNewVariantButton}
          className={productCustomizationFooter.generateNewVariantButton}
          startIcon={
            variantFormState === VariantFormState.GENERATING_NEW_VARIANT ? (
              <CircularProgress size={theme.var.buttonSpinnerSize} />
            ) : undefined
          }
        >
          {text.generate}
        </RightButton>
      </div>
    </ProductCustomizationFooterContainer>
  );
};

export default ProductCustomizationFooter;
