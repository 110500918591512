import {
  EMPTY_STATE_ILLUSTRATION_TYPES,
  MIDEmptyState,
  PreviewImage,
  PreviewInProgressMessageWrap,
  PreviewLoader,
} from '@mid-react-common/common';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import { variantThumbnail } from '../../dataTestIds';
import text from '../../revit-components.text.json';
import { useVariantThumbnail } from './useVariantThumbnail';
import { AccBridgeDownloadUrlQueryParams } from 'mid-api-services';

interface VariantThumbnailProps {
  tenancyId?: string;
  thumbnail?: string;
  isLoading?: boolean;
  alternateMessage?: string;
  incomingAccBridgeData?: AccBridgeDownloadUrlQueryParams;
}

const { thumbnailLoaderContainer, thumbnailContainer } = variantThumbnail;

const VariantThumbnail: React.FC<VariantThumbnailProps> = ({
  tenancyId,
  thumbnail,
  isLoading,
  alternateMessage,
  incomingAccBridgeData,
}) => {
  const { shouldThumbnailBoxShowLoader, thumbnailInBase64, thumbnailError } = useVariantThumbnail({
    tenancyId,
    thumbnail,
    isLoading,
    incomingAccBridgeData,
  });
  const theme = useTheme();
  return (
    <>
      {shouldThumbnailBoxShowLoader ? (
        <PreviewLoader data-testid={thumbnailLoaderContainer}>
          <CircularProgress size={theme.var.circularProgressSize} thickness={theme.var.circularProgressThickness} />
          <PreviewInProgressMessageWrap>{alternateMessage || text.updateInProgressMessage}</PreviewInProgressMessageWrap>
        </PreviewLoader>
      ) : thumbnailInBase64 ? (
        <PreviewImage
          data-testid={thumbnailContainer}
          src={`data:image/jpeg;base64,${thumbnailInBase64}`}
          alt={text.previewImageAltText}
        />
      ) : (
        <MIDEmptyState
          illustrationType={
            thumbnailError ? EMPTY_STATE_ILLUSTRATION_TYPES.SYSTEM_ERROR : EMPTY_STATE_ILLUSTRATION_TYPES.PAGES_PHOTO
          }
          title={thumbnailError ? text.previewNotAvailable : ''}
        />
      )}
    </>
  );
};

export default VariantThumbnail;
