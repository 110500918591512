import {
  CodeRunnerResult,
  CodeRunnerUIExtension,
  codeRunner,
  validationOperations,
} from '@adsk/informed-design-code-runner';
import { DCInput, ProductRelease } from '@adsk/offsite-dc-sdk';
import { getInputValue, updateInputValueInPlace } from '@mid-react-common/addins';
import {
  ConfigurableProductProperties,
  LogAndShowNotificationProps,
  NOTIFICATION_STATUSES,
  StateSetter,
  commonText,
} from '@mid-react-common/common';
import { DCProductUIExtension, FormRules } from 'mid-types';
import { ErrorCode, ForgeValidationError, ProductReleaseError, logError, isProductStatic } from 'mid-utils';
import { useCallback, useEffect, useState } from 'react';

import { VariantFormState, VariantFormStates } from '../interfaces/typings';
import text from '../revit-components.text.json';

interface UseProductCustomizationFormInRevitProps {
  currentProductRelease: DCProductUIExtension<ProductRelease> | undefined;
  configurableProductProperties: ConfigurableProductProperties;
  updateConfigurableProductInputs: (inputs: DCInput[]) => void;
  setVariantFormState: StateSetter<VariantFormStates>;
  logAndShowNotification: (props: LogAndShowNotificationProps) => void;
  showMessageDialog?: (messages: string[]) => void;
  isFormInitializing: boolean;
  setIsFormInitializing: StateSetter<boolean>;
}

interface UseProductCustomizationFormInRevitState {
  isFormInitializing: boolean;
  setIsFormInitializing: StateSetter<boolean>;
  productCustomizationFormError: Error | undefined;
  isFormDataValid: boolean;
  setIsFormDataValid: StateSetter<boolean>;
  inputsError: ForgeValidationError | undefined;
  handleInputUpdate: (inputToUpdate: DCInput) => Promise<void>;
  formRules: FormRules | undefined;
  handleTabChange: (_event: React.SyntheticEvent, newValue: number) => void;
  tabValue: number;
}

const useProductCustomizationFormInRevit = ({
  currentProductRelease,
  configurableProductProperties,
  updateConfigurableProductInputs,
  setVariantFormState,
  logAndShowNotification,
  showMessageDialog,
  isFormInitializing,
  setIsFormInitializing,
}: UseProductCustomizationFormInRevitProps): UseProductCustomizationFormInRevitState => {
  const [productCustomizationFormError, setProductCustomizationFormError] = useState<Error | undefined>();
  const [isFormDataValid, setIsFormDataValid] = useState(true);
  const [inputsError, setInputsError] = useState<ForgeValidationError | undefined>();
  const [formRules, setFormRules] = useState<FormRules | undefined>();
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const enhanceInputsWithCodeRunnerRules = useCallback(
    async (inputs: DCInput[], codeRunnerRuleFromProduct: string | undefined): Promise<CodeRunnerResult | null> => {
      try {
        if (!codeRunnerRuleFromProduct) {
          // nothing to enhance - return original inputs
          return {
            result: inputs as CodeRunnerUIExtension<DCInput>[],
            error: null,
            valueUpdates: [],
          };
        }
        const codeRunnerOutcome = await codeRunner({
          code: codeRunnerRuleFromProduct,
          inputs: inputs as CodeRunnerUIExtension<DCInput>[],
          printCallback: showMessageDialog,
        });

        // We only show errors that are related to the code blocks, not validation errors
        if (codeRunnerOutcome.error && codeRunnerOutcome.error.errorCode === ErrorCode.CodeRunnerError) {
          logAndShowNotification({
            message: codeRunnerOutcome.error.message,
            severity: NOTIFICATION_STATUSES.ERROR,
          });
        }

        return codeRunnerOutcome;
      } catch (e) {
        logError(e, { inputs: configurableProductProperties.inputs });
        setProductCustomizationFormError(Error(text.failedToLoadInputs));
        return null;
      }
    },
    [configurableProductProperties.inputs, showMessageDialog, logAndShowNotification],
  );

  const triggerCodeRunner = useCallback(
    async (inputs: DCInput[]): Promise<void> => {
      if (!currentProductRelease) {
        throw new ProductReleaseError(text.triggerCodeRunnerMissingSelectedProductRelease, {
          currentProductRelease,
        });
      }

      const inputRules: string | undefined = currentProductRelease.rules?.currentRule?.code;

      // TRADES-6972: When publishing a product, the input labels are automatically set to the input name by the API
      // When the product has input rules, the labels are automatically reset by the code runner.
      // For Static products, we want the labels from the form rules to take precedence.
      // Hence, we reset input labels in order for form rules labels to be applied.
      if (!inputRules && isProductStatic(currentProductRelease)) {
        const inputsWithoutLabels = inputs.map((input) => {
          const { label, ...inputWithoutLabel } = input;
          return inputWithoutLabel;
        });
        updateConfigurableProductInputs(inputsWithoutLabels);
        return;
      }

      const enhancedInputs = await enhanceInputsWithCodeRunnerRules(inputs, inputRules);

      const hasCustomErrors = !!enhancedInputs?.error?.errors.some(
        (error) => error.operation === validationOperations.CUSTOM_ERROR_SET_IN_RULE,
      );
      const hasCodeRunnerErrors = enhancedInputs?.error?.errorCode === ErrorCode.CodeRunnerError;
      const hasValidationAutoCorrectedValues = enhancedInputs?.error?.errorCode === ErrorCode.CodeValidationError;

      setIsFormDataValid(true);
      setInputsError(undefined);
      if (hasCustomErrors || hasValidationAutoCorrectedValues) {
        setInputsError(enhancedInputs?.error || undefined);

        if (hasCustomErrors) {
          setIsFormDataValid(false);
        }
      } else if (hasCodeRunnerErrors) {
        setIsFormDataValid(false);
      }

      // Code runner result might not pass validation. We will update code runner
      // result regardless we have received validation errors.
      if (enhancedInputs?.result) {
        updateConfigurableProductInputs(enhancedInputs.result);
      }
    },
    [currentProductRelease, enhanceInputsWithCodeRunnerRules, updateConfigurableProductInputs],
  );

  const handleInputUpdate = async (inputToUpdate: DCInput): Promise<void> => {
    // Do not trigger code runner if the input value is the same as the one in the data store
    const inputValueInDataStore = getInputValue(configurableProductProperties.inputs, inputToUpdate);
    if (inputValueInDataStore === inputToUpdate.value) {
      return;
    }

    const updatedInputs = updateInputValueInPlace(configurableProductProperties.inputs, inputToUpdate);

    setVariantFormState(VariantFormState.EDITING_NEW_VARIANT);

    return await triggerCodeRunner(updatedInputs);
  };

  useEffect(() => {
    const initializeFormDataWithCodeRunner = async () => {
      if (isFormInitializing && currentProductRelease) {
        // We initialize the form rules once, since they are not expected to change
        if (currentProductRelease?.rules?.formRules?.code) {
          try {
            const parsedFormRules: FormRules = JSON.parse(currentProductRelease.rules.formRules.code);
            setFormRules(parsedFormRules);
          } catch (e) {
            logAndShowNotification({
              message: commonText.failedToParseFormRules,
              severity: NOTIFICATION_STATUSES.ERROR,
            });
          }
        }
        await triggerCodeRunner(configurableProductProperties.inputs);
        setIsFormInitializing(false);
      }
    };

    initializeFormDataWithCodeRunner();
  }, [
    configurableProductProperties.inputs,
    currentProductRelease,
    isFormInitializing,
    logAndShowNotification,
    setIsFormInitializing,
    triggerCodeRunner,
  ]);

  return {
    isFormInitializing,
    setIsFormInitializing,
    productCustomizationFormError,
    isFormDataValid,
    setIsFormDataValid,
    inputsError,
    handleInputUpdate,
    formRules,
    tabValue,
    handleTabChange,
  };
};

export default useProductCustomizationFormInRevit;
