import { FormControl, FormLabel, Switch } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import React from 'react';
import { SwitchFormGroupWrap } from './SwitchButtonGroup.styles';
interface SwitchButtonGroupProps {
  name: string;
  label: string;
  readOnly?: boolean;
  value: boolean;
  error?: boolean;
  trueLabel?: string;
  falseLabel?: string;
  isProductConfigurable?: boolean;
  onSwitchButtonSelectionChange: (newValue: boolean) => void;
}

export const SwitchButtonGroup: React.FC<SwitchButtonGroupProps> = ({
  name,
  readOnly,
  value,
  label,
  error,
  trueLabel,
  falseLabel,
  isProductConfigurable = true,
  onSwitchButtonSelectionChange,
}) => {
  const trueOrFalseLabelExist = typeof trueLabel !== 'undefined' || typeof falseLabel !== 'undefined';
  const getSwitchLabel = (value: boolean): string => {
    if (!trueOrFalseLabelExist) {
      // Both true and false label do not exist, it set to label
      return label;
    }
    const definedTruelLabel = typeof trueLabel !== 'undefined' ? trueLabel : 'True';
    const definedFalselLabel = typeof falseLabel !== 'undefined' ? falseLabel : 'False';
    return value ? definedTruelLabel : definedFalselLabel;
  };
  const switchLabel = getSwitchLabel(value);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = event.target.checked;
    onSwitchButtonSelectionChange(currentValue);
  };

  const disabled = readOnly || !isProductConfigurable;

  return (
    <FormControl>
      {trueOrFalseLabelExist ? <FormLabel>{label}</FormLabel> : null}
      <SwitchFormGroupWrap>
        <FormControlLabel
          control={<Switch checked={value} onChange={handleChange} size="small" color={error ? 'error' : 'primary'} />}
          label={switchLabel}
          disabled={disabled}
          data-testid={`label-${name}`}
        />
      </SwitchFormGroupWrap>
    </FormControl>
  );
};
