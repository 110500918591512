import React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import text from '../../revit-components.text.json';
import { FlexContainer, staticVsConfigurableProductsUrl } from '@mid-react-common/common';
import { staticContentFormNotificationTestId } from '../../dataTestIds';

interface FormNotificationProps {
  message: string;
}

const FormNotificationCard = styled(Card)`
  border-left: ${({ theme }) => `${theme.var.navBorderWidth}px solid ${theme.colors.primary.autodeskBlue[500]}`};
`;

const FormNotificationCardMedia = styled(CardMedia)`
  margin-right: ${({ theme }) => `${theme.var.marginBase * 2}px`};
`;

const FormNotificationLink = styled('a')`
  color: ${({ theme }) => theme.colors.primary.autodeskBlue[500]};
  text-decoration: none;
`;

const StaticContentFormNotification: React.FC<FormNotificationProps> = ({ message }) => {
  const hasBeenClosed = window.sessionStorage.getItem('staticContentFormNotificationClosed') === 'true';
  const [open, setOpen] = React.useState(!hasBeenClosed);

  const handleCloseButtonClick = () => {
    window.sessionStorage.setItem('staticContentFormNotificationClosed', 'true');
    setOpen(false);
  };

  if (!open) {
    return null;
  }

  return (
    <FormNotificationCard data-testid={staticContentFormNotificationTestId}>
      <FlexContainer>
        <CardContent>
          <FlexContainer>
            <FormNotificationCardMedia>
              <InfoOutlinedIcon color="info" />
            </FormNotificationCardMedia>
            <div>
              <Typography>{message}</Typography>
              <FormNotificationLink href={staticVsConfigurableProductsUrl} target="_blank" rel="noopener noreferrer">
                {text.staticVsConfigurableProductsLink}
              </FormNotificationLink>
            </div>
          </FlexContainer>
        </CardContent>
        <CardActions>
          <IconButton color="info" onClick={handleCloseButtonClick} size="small">
            <CloseIcon fontSize="small" />
          </IconButton>
        </CardActions>
      </FlexContainer>
    </FormNotificationCard>
  );
};

export default StaticContentFormNotification;
