import InputLabel from '@mui/material/InputLabel';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectProps } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { uniqueId } from 'lodash';
import React, { useRef, ReactElement } from 'react';
import { MIDDropdownCircularProgress, StyledFormControl, styledPaperPopover } from './Dropdown.styles';
import { DropdownProps, MIDMenuItem } from './Dropdown.types';
import { addDoubleQuotes, removeDoubleQuotes } from 'mid-utils';

const isMenuItem = (item: unknown): item is MIDMenuItem => (item as ReactElement).type === MenuItem;

const isString = (value: unknown): value is string => typeof value === 'string';

const isStringOrNumber = (value: unknown): value is string | number =>
  typeof value === 'string' || typeof value === 'number';

const Dropdown: React.FC<DropdownProps> = ({
  elements,
  selectedItem,
  controlIcon: ControlIcon,
  menuItemIcon: MenuItemIcon,
  onSelect,
  disabled,
  label,
  error,
  width,
  responsive,
  isLoadingElements,
  maxHeight,
  doubleQuotesControl,
  ...props
}) => {
  const labelId = useRef(uniqueId('dropdown-input-label'));

  const onChange: SelectProps<string | number>['onChange'] = (event, child) => {
    if (isMenuItem(child) && isStringOrNumber(event.target.value)) {
      let value = event.target.value;

      if (doubleQuotesControl) {
        if (isString(value)) {
          value = addDoubleQuotes(value);
        }
      }

      onSelect({
        selectedItem: {
          id: child.props['data-id'],
          label: child.props['data-label'],
          value,
        },
      });
    }
  };

  const renderValue: SelectProps<string | number>['renderValue'] = (value) =>
    ControlIcon ? (
      <Stack direction="row">
        <ControlIcon />
        {value}
      </Stack>
    ) : (
      value
    );

  const selectedValue = doubleQuotesControl
    ? removeDoubleQuotes(selectedItem?.value.toString() || '')
    : (selectedItem?.value.toString() ?? '');

  return (
    <StyledFormControl size="small" width={width} error={error} {...(responsive && { $responsive: responsive })}>
      <InputLabel id={labelId.current}>
        <Stack direction="row" justifyContent="space-between">
          {label}
          {isLoadingElements && <MIDDropdownCircularProgress size={23} />}
        </Stack>
      </InputLabel>

      <Select
        value={selectedValue}
        onChange={onChange}
        renderValue={renderValue}
        displayEmpty
        disabled={disabled}
        label={label}
        labelId={labelId.current}
        MenuProps={{
          PaperProps: {
            style: styledPaperPopover(maxHeight),
          },
        }}
        {...props}
      >
        {elements.map((element) => (
          <MenuItem key={element.value} value={element.value} data-id={element.id} data-label={element.label.toString()}>
            {MenuItemIcon && (
              <ListItemIcon>
                <MenuItemIcon />
              </ListItemIcon>
            )}
            <ListItemText>
              {element.label}
              {element.subtext}
            </ListItemText>
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
};

export default Dropdown;
