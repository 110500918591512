export const testIds = {
  midEditFormTryAgainButton: 'mid-edit-form-try-again-button',
  midEditUpdatePreviewButton: 'mid-edit-update-preview-button',
  midEditFormApplyButton: 'mid-edit-form-apply-button',
  midEditFormDetailsWrapper: 'mid-edit-form-details-wrapper',
  midEditFormSpinnerOverlay: 'mid-edit-form-spinner-overlay',
  midEditFormChangeReleaseButton: 'mid-edit-form-change-release-button',
  midEditFormChangeReleaseDropdown: 'mid-edit-form-change-release-dropdown',
  midEditFormReleaseStatus: 'mid-edit-form-release-status',
  representationContainer: 'mid-edit-form-representation-container',
  editRepresentationButton: 'mid-edit-form-edit-representation-button',
  representationDropdown: 'mid-edit-form-representation-dropdown',
  closeRepresentationSelection: 'mid-edit-form-close-representation-selection',
  configureTab: 'mid-edit-form-configure-tab',
  selectTab: 'mid-edit-form-select-tab',
  changeReleaseButton: 'mid-edit-form-change-release-button',
  replaceVariantButton: 'mid-edit-form-replace-button',
  previewButton: 'mid-edit-form-preview-button',
};
