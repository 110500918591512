import { ProductRelease, ReleaseStatus } from '@adsk/offsite-dc-sdk';
import { DropdownItem } from '@mid-react-common/addins';
import { StateSetter } from '@mid-react-common/common';
import { DropdownItemSubText } from 'components/Header/Header.styles';
import { useEffect, useMemo, useState } from 'react';
import text from '../../global/mid-edit-form.text.json';

interface UseChangeReleaseProps {
  nonObsoleteProductReleasesList: ProductRelease[] | undefined;
  selectedProductReleaseNumber: number;
  handleProductReleaseChange: (productRelease: ProductRelease) => void;
  setReFetchCachedVariants: StateSetter<boolean>;
}

interface UseChangeReleaseState {
  showChangeReleaseButton: boolean | undefined;
  showChangeReleaseDropdown: boolean;
  productReleaseDropdownItems: DropdownItem[];
  selectedProductReleaseDropdownItem: DropdownItem | null;
  handleChangeReleaseClick: () => void;
  handleChangeReleaseSelection: ({ selectedItem }: { selectedItem?: DropdownItem | null }) => void;
}

const useChangeRelease = ({
  nonObsoleteProductReleasesList,
  selectedProductReleaseNumber,
  handleProductReleaseChange,
  setReFetchCachedVariants,
}: UseChangeReleaseProps): UseChangeReleaseState => {
  const [showChangeReleaseDropdown, setShowChangeReleaseDropdown] = useState(false);

  const atLeastOneNonObsoleteProductRelease = nonObsoleteProductReleasesList && nonObsoleteProductReleasesList.length > 0;
  const showChangeReleaseButton = atLeastOneNonObsoleteProductRelease && !showChangeReleaseDropdown;

  const productReleaseDropdownItems = useMemo(
    () =>
      nonObsoleteProductReleasesList?.map((productRelease) => ({
        id: productRelease.release,
        value: `${text.MIDEditForm.releasesPrefix} ${productRelease.release}`,
        label: `${text.MIDEditForm.releasesPrefix} ${productRelease.release}`,
        subtext:
          productRelease.status === ReleaseStatus.DEFAULT ? (
            <DropdownItemSubText> {productRelease.status}</DropdownItemSubText>
          ) : undefined,
      })) || [],
    [nonObsoleteProductReleasesList],
  );

  const [selectedProductReleaseDropdownItem, setSelectedProductReleaseDropdownItem] = useState<DropdownItem | null>(
    productReleaseDropdownItems.find(
      (productReleaseDropdownItem) => productReleaseDropdownItem.id === selectedProductReleaseNumber,
    ) || null,
  );

  const handleChangeReleaseClick = () => {
    setShowChangeReleaseDropdown(true);
  };

  const handleChangeReleaseSelection = ({ selectedItem }: { selectedItem?: DropdownItem | null }) => {
    if (selectedItem && typeof selectedItem.value === 'string') {
      const selectedItemReleaseNumber = selectedItem.value.replace(`${text.MIDEditForm.releasesPrefix} `, '');
      const selectedProductRelease = nonObsoleteProductReleasesList?.find(
        (productRelease) => productRelease.release === Number(selectedItemReleaseNumber),
      );

      if (selectedProductRelease) {
        setSelectedProductReleaseDropdownItem(selectedItem);
        handleProductReleaseChange(selectedProductRelease);
        setReFetchCachedVariants(true);
      }
    }
  };

  useEffect(() => {
    const selectedDropdownItem =
      productReleaseDropdownItems.find(
        (productReleaseDropdownItem) => productReleaseDropdownItem.id === selectedProductReleaseNumber,
      ) || null;
    setSelectedProductReleaseDropdownItem(selectedDropdownItem);
  }, [selectedProductReleaseNumber, productReleaseDropdownItems]);

  return {
    showChangeReleaseButton,
    showChangeReleaseDropdown,
    productReleaseDropdownItems,
    selectedProductReleaseDropdownItem,
    handleChangeReleaseClick,
    handleChangeReleaseSelection,
  };
};

export default useChangeRelease;
