import {
  CompleteUploadPayload,
  CompleteUploadResponse,
  DownloadURLPayload,
  DownloadUrlResponse,
  DynamicContent,
  GetVariantLogResponse,
  OutputTypes,
  PostProductPayload,
  PostVariantPayload,
  ProductRelease,
  ProductUserConfiguration,
  PutProductPayload,
  PutProductUserConfigurationPayload,
  ReleaseStatusValue,
  SignedUploadUrlsResponse,
  UploadURLsPayload,
  Variant,
  VariantOutput,
  GetUploadOutputsRequestResponse,
  GetCanGenerateOutputsResponse,
} from '@adsk/offsite-dc-sdk';
import { inject, injectable } from 'inversify';
import 'reflect-metadata';
import * as apiService from './api.service';
import type { AuthHandler, Environment, ForgeQueryResponse } from 'mid-types';
import { InversifyTypes } from './inversify/inversifyTypes';
import { LDContext } from 'launchdarkly-js-sdk-common';

export type AllAccBridgeQueryParams = {
  sourceFolderUrn?: string;
  sourceProjectId?: string;
  targetFolderUrn?: string;
  targetProjectId?: string;
};

export type AccBridgeSourceProjectDataQueryParams = Omit<AllAccBridgeQueryParams, 'targetFolderUrn'>;
export type AccBridgeDownloadUrlQueryParams = Omit<AllAccBridgeQueryParams, 'sourceFolderUrn' | 'targetFolderUrn'>;

interface PostVariantArgs {
  projectId: string;
  productId: string;
  postVariantPayload: PostVariantPayload;
  incomingAccBridgeData?: AccBridgeSourceProjectDataQueryParams;
}

interface GetVariantsListArgs {
  projectId: string;
  productId: string;
  incomingAccBridgeData?: AccBridgeSourceProjectDataQueryParams;
  signal?: AbortSignal;
}

interface GetProductReleasesListArgs {
  projectId: string;
  productId: string;
  incomingAccBridgeData?: AccBridgeSourceProjectDataQueryParams;
  signal?: AbortSignal;
}

interface GetProductReleaseArgs extends GetProductReleasesListArgs {
  releaseNumber: number;
}

interface GetVariantArgs {
  projectId: string;
  productId: string;
  variantId: string;
  incomingAccBridgeData?: AccBridgeSourceProjectDataQueryParams;
}

interface DownloadUrlArgs {
  projectId: string;
  downloadURLPayload: DownloadURLPayload;
  incomingAccBridgeData?: AccBridgeDownloadUrlQueryParams;
}

interface UpdateProductUserConfigurationArgs {
  projectId: string;
  productId: string;
  productUserConfigurationPayload: PutProductUserConfigurationPayload;
  incomingAccBridgeData?: AccBridgeSourceProjectDataQueryParams;
}

interface GetVariantUploadRequestsArgs {
  projectId: string;
  productId: string;
  variantId: string;
  incomingAccBridgeData?: AccBridgeSourceProjectDataQueryParams;
  signal?: AbortSignal;
}

@injectable()
export class DcApiService {
  private apiService: apiService.ApiService;

  constructor(
    @inject(InversifyTypes.DcApiBaseURL) baseURL: string,
    @inject(InversifyTypes.AuthHandler) authHandler: AuthHandler,
    @inject(InversifyTypes.Env) env: Environment,
  ) {
    this.apiService = new apiService.ApiService(baseURL, '', env, {}, authHandler);
  }

  //#region Products Service

  /**
   * Creates a product
   * @param projectId
   * @param postProductPayload
   */
  postProduct = async (projectId: string, postProductPayload: PostProductPayload): Promise<DynamicContent> => {
    const postProductEndpoint = `projects/${projectId}/products`;
    const response = await this.apiService.post(postProductEndpoint, postProductPayload);

    return response.data;
  };

  /**
   * Gets a product
   * @param projectId
   * @param productId
   */
  getProduct = async (projectId: string, productId: string): Promise<DynamicContent> => {
    const getProductEndpoint = `projects/${projectId}/products/${productId}`;
    const response = await this.apiService.get(getProductEndpoint);

    return response.data;
  };

  /**
   * Gets products list
   */
  getProductsList = async ({
    projectId,
    targetFolderUrn,
    signal,
  }: {
    projectId: string;
    targetFolderUrn?: string;
    signal?: AbortSignal;
  }): Promise<DynamicContent[]> => {
    // targetFolderUrn when it is in a Incoming Bridge Folder
    const getProductsListEndpoint = targetFolderUrn
      ? `projects/${projectId}/products?targetFolderUrn=${targetFolderUrn}`
      : `projects/${projectId}/products`;

    const productsList: DynamicContent[] = [];

    let nextUrl: string | undefined = getProductsListEndpoint;

    while (typeof nextUrl !== 'undefined') {
      const response = await this.apiService.get(nextUrl, { signal });
      const { pagination, results } = response.data as ForgeQueryResponse<DynamicContent>;
      productsList.push(...results);
      nextUrl = pagination.nextUrl;
    }

    return productsList;
  };

  /**
   * Updates a product
   * @param projectId
   * @param productId
   * @param putProductPayload
   */
  updateProduct = async (
    projectId: string,
    productId: string,
    putProductPayload: PutProductPayload,
  ): Promise<DynamicContent> => {
    const updateProductEndpoint = `projects/${projectId}/products/${productId}`;
    const response = await this.apiService.put(updateProductEndpoint, putProductPayload);

    return response.data;
  };

  /**
   * Deletes or archives a product
   * @param projectId
   * @param productId
   * @param archive
   */
  deleteProduct = async (projectId: string, productId: string, archive?: boolean): Promise<DynamicContent | null> => {
    const configOptions: apiService.RequestOptions = {
      params: { archive },
    };

    const deleteProductEndpoint = `projects/${projectId}/products/${productId}`;
    const response = await this.apiService.delete(deleteProductEndpoint, configOptions);

    return response.data;
  };

  //#endregion

  //#region Products Releases Service

  /**
   * Gets a product release
   * @param projectId
   * @param productId
   * @param releaseNumber
   * @param incomingAccBridgeData
   */
  getProductRelease = async ({
    projectId,
    productId,
    releaseNumber,
    incomingAccBridgeData,
  }: GetProductReleaseArgs): Promise<ProductRelease> => {
    let getProductReleaseEndpoint = `projects/${projectId}/products/${productId}/releases/${releaseNumber}`;

    const { sourceFolderUrn, sourceProjectId, targetProjectId } = incomingAccBridgeData || {};
    const allAccBridgeArgs = sourceFolderUrn && sourceProjectId && targetProjectId;

    if (allAccBridgeArgs) {
      getProductReleaseEndpoint = `projects/${targetProjectId}/products/${productId}/releases/${releaseNumber}`;
    }

    const response = await this.apiService.get(getProductReleaseEndpoint, {
      params: allAccBridgeArgs ? { sourceFolderUrn, sourceProjectId } : undefined,
    });

    return response.data;
  };

  getProductReleasesList = async ({
    projectId,
    productId,
    incomingAccBridgeData,
    signal,
  }: GetProductReleasesListArgs): Promise<ProductRelease[]> => {
    let getProductReleasesListEndpoint = `projects/${projectId}/products/${productId}/releases`;

    const { sourceFolderUrn, sourceProjectId, targetProjectId } = incomingAccBridgeData || {};
    const allAccBridgeArgs = sourceFolderUrn && sourceProjectId && targetProjectId;

    if (allAccBridgeArgs) {
      getProductReleasesListEndpoint = `projects/${targetProjectId}/products/${productId}/releases`;
    }

    const productReleasesList: ProductRelease[] = [];
    let nextUrl: string | undefined = getProductReleasesListEndpoint;

    while (typeof nextUrl !== 'undefined') {
      const response = await this.apiService.get(nextUrl, {
        params: allAccBridgeArgs ? { sourceFolderUrn, sourceProjectId } : undefined,
        signal,
      });
      const { pagination, results } = response.data as ForgeQueryResponse<ProductRelease>;
      productReleasesList.push(...results);
      nextUrl = pagination.nextUrl;
    }

    return productReleasesList;
  };

  /**
   * Updates a product release
   * @param projectId
   * @param productId
   * @param release
   * @param status
   */
  updateProductRelease = async (
    projectId: string,
    productId: string,
    release: number,
    status: ReleaseStatusValue,
  ): Promise<ProductRelease> => {
    const updateProductReleaseEndpoint = `projects/${projectId}/products/${productId}/releases/${release}`;
    const response = await this.apiService.patch(updateProductReleaseEndpoint, { status });

    return response.data;
  };

  updateProductReleaseNotes = async (
    projectId: string,
    productId: string,
    release: number,
    notes: string,
  ): Promise<ProductRelease> => {
    const updateProductReleaseNotesEndpoint = `projects/${projectId}/products/${productId}/releases/${release}`;
    const response = await this.apiService.patch(updateProductReleaseNotesEndpoint, { notes });

    return response.data;
  };
  //#endregion

  //#region Variants Service

  /**
   * Creates a variant
   * @param projectId
   * @param productId
   * @param postVariantPayload
   * @param incomingAccBridgeData
   */
  postVariant = async ({
    projectId,
    productId,
    postVariantPayload,
    incomingAccBridgeData,
  }: PostVariantArgs): Promise<Variant> => {
    const { sourceFolderUrn, sourceProjectId, targetProjectId } = incomingAccBridgeData || {};

    let postVariantEndpoint = `projects/${projectId}/products/${productId}/variants`;

    if (sourceFolderUrn && sourceProjectId && targetProjectId) {
      postVariantEndpoint = `projects/${targetProjectId}/products/${productId}/variants?sourceFolderUrn=${encodeURIComponent(
        sourceFolderUrn,
      )}&sourceProjectId=${sourceProjectId}`;
    }
    const response = await this.apiService.post(postVariantEndpoint, postVariantPayload);

    return response.data;
  };

  /**
   * Creates a variant and upload the outputs
   * @param projectId
   * @param productId
   * @param postVariantPayload
   * @param sourceFolderUrn
   * @param sourceProjectId
   */
  uploadVariantOutputs = async ({
    projectId,
    productId,
    postVariantPayload,
    incomingAccBridgeData,
  }: {
    projectId: string;
    productId: string;
    postVariantPayload: PostVariantPayload;
    incomingAccBridgeData?: AccBridgeSourceProjectDataQueryParams;
  }): Promise<Variant> => {
    const { sourceFolderUrn, sourceProjectId, targetProjectId } = incomingAccBridgeData || {};

    const uploadVariantEndpoint =
      targetProjectId && sourceFolderUrn && sourceProjectId
        ? `projects/${targetProjectId}/products/${productId}/uploadoutputs?sourceFolderUrn=${encodeURIComponent(
            sourceFolderUrn,
          )}&sourceProjectId=${sourceProjectId}`
        : `projects/${projectId}/products/${productId}/uploadoutputs`;
    const response = await this.apiService.post(uploadVariantEndpoint, postVariantPayload);

    return response.data;
  };

  getVariant = async ({ projectId, productId, variantId, incomingAccBridgeData }: GetVariantArgs): Promise<Variant> => {
    let getVariantEndpoint = `projects/${projectId}/products/${productId}/variants/${variantId}`;

    const { sourceFolderUrn, sourceProjectId, targetProjectId } = incomingAccBridgeData || {};
    const allAccBridgeArgs = sourceFolderUrn && sourceProjectId && targetProjectId;
    if (allAccBridgeArgs) {
      getVariantEndpoint = `projects/${targetProjectId}/products/${productId}/variants/${variantId}`;
    }
    const response = await this.apiService.get(getVariantEndpoint, {
      params: allAccBridgeArgs ? { sourceFolderUrn, sourceProjectId } : undefined,
    });

    return response.data;
  };

  /**
   * Gets variants list
   * @param projectId
   * @param productId
   * @param incomingAccBridgeData
   * @param signal
   */

  getVariantsList = async ({
    projectId,
    productId,
    incomingAccBridgeData,
    signal,
  }: GetVariantsListArgs): Promise<Variant[]> => {
    const { sourceFolderUrn, sourceProjectId, targetProjectId } = incomingAccBridgeData || {};
    let getVariantsListEndpoint = `projects/${projectId}/products/${productId}/variants`;

    const allAccBridgeParamsReceived = sourceFolderUrn && sourceProjectId && targetProjectId;
    if (allAccBridgeParamsReceived) {
      getVariantsListEndpoint = `projects/${targetProjectId}/products/${productId}/variants`;
    }

    const variantsList: Variant[] = [];

    let nextUrl: string | undefined = getVariantsListEndpoint;

    while (typeof nextUrl !== 'undefined') {
      const response = await this.apiService.get(nextUrl, {
        signal,
        params: allAccBridgeParamsReceived ? { sourceFolderUrn, sourceProjectId } : undefined,
      });
      const { pagination, results } = response.data as ForgeQueryResponse<Variant>;
      variantsList.push(...results);
      nextUrl = pagination.nextUrl;
    }

    return variantsList;
  };

  /**
   * Gets variant outputs
   * @param projectId
   * @param productId
   * @param variantId
   */
  getVariantOutputs = async (projectId: string, productId: string, variantId: string): Promise<VariantOutput[]> => {
    const getVariantOutputsEndpoint = `projects/${projectId}/products/${productId}/variants/${variantId}/outputs`;
    const response = await this.apiService.get(getVariantOutputsEndpoint);

    return response.data;
  };

  /**
   * Gets variant output by type
   * @param projectId
   * @param productId
   * @param variantId
   * @param outputType
   */
  getVariantOutputsByType = async (
    projectId: string,
    productId: string,
    variantId: string,
    type: OutputTypes,
  ): Promise<VariantOutput[]> => {
    const configOptions: apiService.RequestOptions = {
      params: { type },
    };

    const getVariantOutputsByTypeEndpoint = `projects/${projectId}/products/${productId}/variants/${variantId}/outputs`;
    const response = await this.apiService.get(getVariantOutputsByTypeEndpoint, configOptions);

    return response.data;
  };

  /**
   * Deletes a variant
   * @param projectId
   * @param productId
   * @param variantId
   */
  deleteVariant = async (projectId: string, productId: string, variantId: string): Promise<any> => {
    const deleteVariantEndpoint = `projects/${projectId}/products/${productId}/variants/${variantId}`;
    const response = await this.apiService.delete(deleteVariantEndpoint);

    return response.data;
  };

  /**
   * Delete variants
   * @param projectId
   * @param productId
   */
  deleteVariants = async (projectId: string, productId: string): Promise<any> => {
    const deleteVariantsEndpoint = `projects/${projectId}/products/${productId}/variants`;
    const response = await this.apiService.delete(deleteVariantsEndpoint);

    return response.data;
  };

  /**
   * Gets a variant log
   * @param projectId
   * @param productId
   * @param variantId
   * @param incomingAccBridgeData
   */
  getVariantLog = async ({
    projectId,
    productId,
    variantId,
    incomingAccBridgeData,
  }: GetVariantArgs): Promise<GetVariantLogResponse> => {
    const { sourceFolderUrn, sourceProjectId, targetProjectId } = incomingAccBridgeData || {};

    const allAccBridgeParamsReceived = targetProjectId && sourceFolderUrn && sourceProjectId;

    const getVariantLogEndpoint = allAccBridgeParamsReceived
      ? `projects/${targetProjectId}/products/${productId}/variants/${variantId}/logs`
      : `projects/${projectId}/products/${productId}/variants/${variantId}/logs`;

    const response = await this.apiService.get(getVariantLogEndpoint, {
      params: allAccBridgeParamsReceived ? { sourceFolderUrn, sourceProjectId } : undefined,
    });

    return response.data;
  };

  //#endregion

  //#region User Configurations Service
  updateProductUserConfiguration = async ({
    projectId,
    productId,
    productUserConfigurationPayload,
    incomingAccBridgeData,
  }: UpdateProductUserConfigurationArgs): Promise<ProductUserConfiguration> => {
    const { sourceFolderUrn, sourceProjectId, targetProjectId } = incomingAccBridgeData || {};

    const putProductUserConfigPath =
      sourceFolderUrn && sourceProjectId && targetProjectId
        ? `userconfigs/projects/${targetProjectId}/products/${productId}?sourceProjectId=${sourceProjectId}&sourceFolderUrn=${encodeURIComponent(
            sourceFolderUrn,
          )}`
        : `userconfigs/projects/${projectId}/products/${productId}`;
    const response = await this.apiService.put(putProductUserConfigPath, productUserConfigurationPayload);

    return response.data;
  };

  canGenerateOutputs = async <T = GetCanGenerateOutputsResponse>(
    requestedOutputsCount: number,
    signal?: AbortSignal,
  ): Promise<T> =>
    (
      await this.apiService.get<T>('userconfigs/cangenerateoutputs', {
        params: {
          requestedOutputsCount,
        },
        signal,
      })
    ).data;

  //#endregion

  //#region Data Service

  /**
   * Gets an array of signed URLs to upload an object in multiple parts.
   * @param projectId
   * @param uploadURLsPayload
   */
  getUploadURLs = async (projectId: string, uploadURLsPayload: UploadURLsPayload): Promise<SignedUploadUrlsResponse> => {
    const getUploadURLsEndpoint = `projects/${projectId}/data/uploadurls`;
    const response = await this.apiService.post(getUploadURLsEndpoint, uploadURLsPayload);

    return response.data;
  };

  /**
   * Marks completion of the object's upload process after its multiple parts have been uploaded.
   * @param projectId
   * @param completeUploadPayload
   */
  completeUpload = async (
    projectId: string,
    completeUploadPayload: CompleteUploadPayload,
  ): Promise<CompleteUploadResponse> => {
    const completeUploadEndpoint = `projects/${projectId}/data/completeupload`;
    const response = await this.apiService.post(completeUploadEndpoint, completeUploadPayload);

    return response.data;
  };

  /**
   * Gets a signed URL to a download file.
   * @param projectId
   * @param downloadURLPayload
   * @param incomingAccBridgeData
   */
  downloadURL = async ({
    projectId,
    downloadURLPayload,
    incomingAccBridgeData,
  }: DownloadUrlArgs): Promise<DownloadUrlResponse> => {
    const { sourceProjectId, targetProjectId } = incomingAccBridgeData || {};
    let downloadURLEndpoint = `projects/${projectId}/data/downloadurl`;
    if (sourceProjectId && targetProjectId) {
      downloadURLEndpoint = `projects/${targetProjectId}/data/downloadurl?sourceProjectId=${sourceProjectId}`;
    }
    const response = await this.apiService.post(downloadURLEndpoint, downloadURLPayload);

    return response.data;
  };

  //#endregion

  getVariantOutputUploadRequests = async ({
    projectId,
    productId,
    variantId,
    incomingAccBridgeData,
    signal,
  }: GetVariantUploadRequestsArgs): Promise<GetUploadOutputsRequestResponse[]> => {
    const { sourceProjectId, sourceFolderUrn, targetProjectId } = incomingAccBridgeData || {};

    const getVariantOutputUploadRequestsEndpoint = targetProjectId
      ? `projects/${targetProjectId}/products/${productId}/variants/${variantId}/uploadrequests`
      : `projects/${projectId}/products/${productId}/variants/${variantId}/uploadrequests`;

    const uploadResults: GetUploadOutputsRequestResponse[] = [];
    let nextUrl: string | undefined = getVariantOutputUploadRequestsEndpoint;

    while (typeof nextUrl !== 'undefined') {
      const response = await this.apiService.get(nextUrl, {
        signal,
        params: sourceFolderUrn && sourceProjectId ? { sourceFolderUrn, sourceProjectId } : undefined,
      });

      const { pagination, results } = response.data as ForgeQueryResponse<GetUploadOutputsRequestResponse>;
      uploadResults.push(...results);
      nextUrl = pagination.nextUrl;
    }

    return uploadResults;
  };

  getHashForLaunchDarkly = async (context: LDContext): Promise<string> => {
    const ldServerHashUrl = `ld_server_hash`;
    const response = await this.apiService.post(ldServerHashUrl, context);

    return response.data;
  };
}
