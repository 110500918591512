import { AddinContainer } from '@mid-react-common/addins';
import {
  ConfirmationModal,
  ErrorBoundary,
  ErrorFallback,
  MIDEditFormAppMoniker,
  ModalContext,
  NotificationsProvider,
  UnderMaintenance,
  UserAnalyticsProvider,
  createBugsnagErrorBoundaryComponent,
  darkTheme,
  lightTheme,
  useModalStore,
  useNotificationStore,
  QueryClientProvider,
} from '@mid-react-common/common';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import { ThemeProvider } from '@mui/material/styles';
import HeaderWithTabs from 'components/HeaderWithTabs/HeaderWithTabs';
import { DataProvider } from 'context/Data.context';
import TabsContext from 'context/Tabs/Tabs.context';
import { useTabsStore } from 'context/Tabs/tabsStore';
import useDataStore from 'context/dataStore';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { browserApiService, getCurrentAuthToken, getEnvironment } from 'mid-addin-lib';
import { InversifyTypes, inversifyContainer, registerAuthHandler, registerApiBaseURL, registerEnv } from 'mid-api-services';
import { ServiceConfigMap, ServiceTypes } from 'mid-utils';
import React, { ReactElement, useEffect, useState } from 'react';
import { ScreenManager } from 'screens/ScreenManager';

const handleResetAppState = (): void => window.location.reload();
const BugsnagErrorBoundaryComponent = createBugsnagErrorBoundaryComponent({
  moniker: MIDEditFormAppMoniker,
  apiKey: import.meta.env.VITE_BUGSNAG_API_KEY_ADDINS || '',
  appVersion: import.meta.env.VITE_MIDEF_VERSION || 'Not provided',
});

const App: React.FC = (): JSX.Element => {
  const { enableMaintenanceMode, enableDarkModeSwitch, midEditFormWebComponentUnderMaintenance } = useFlags();
  const notificationStore = useNotificationStore();
  const [dark, setDark] = useState<boolean>(false);
  const [apiServicesInitialized, setApiServicesInitialized] = useState(false);
  const dataStore = useDataStore();
  const modalStore = useModalStore();
  const tabsStore = useTabsStore();

  useEffect(() => {
    const registerDependency = async () => {
      const env = await getEnvironment();

      const dcApiBaseURL = await browserApiService.getDcApiUrl();
      const forgeApiBaseUrl = ServiceConfigMap[ServiceTypes.FORGE_API][env];

      if (!inversifyContainer.isBound(InversifyTypes.Env)) {
        registerEnv(env);
      }

      if (!inversifyContainer.isBound(InversifyTypes.AuthHandler)) {
        registerAuthHandler(() => getCurrentAuthToken());
      }

      if (!inversifyContainer.isBound(InversifyTypes.DcApiBaseURL)) {
        registerApiBaseURL(InversifyTypes.DcApiBaseURL, dcApiBaseURL);
      }

      if (!inversifyContainer.isBound(InversifyTypes.ForgeApiBaseURL)) {
        registerApiBaseURL(InversifyTypes.ForgeApiBaseURL, forgeApiBaseUrl.api);
      }

      setApiServicesInitialized(true);
    };
    registerDependency();
  }, []);

  const handleThemeChange = () => {
    setDark((state) => !state);
  };

  const appTree = (
    <ThemeProvider theme={dark ? darkTheme : lightTheme}>
      <CssBaseline />
      <AddinContainer>
        <NotificationsProvider store={notificationStore}>
          {apiServicesInitialized && (
            <QueryClientProvider>
              <UserAnalyticsProvider webComponentMoniker={MIDEditFormAppMoniker}>
                {enableMaintenanceMode || midEditFormWebComponentUnderMaintenance ? (
                  <UnderMaintenance />
                ) : (
                  <>
                    {enableDarkModeSwitch && (
                      <Switch
                        onChange={handleThemeChange}
                        sx={{ position: 'absolute', top: 0, left: '50%', zIndex: 1000 }}
                      />
                    )}
                    <ModalContext.Provider value={modalStore}>
                      <ConfirmationModal isAddin />
                      <DataProvider store={dataStore}>
                        <TabsContext.Provider value={tabsStore}>
                          <HeaderWithTabs />
                          <Divider />
                          <ScreenManager />
                        </TabsContext.Provider>
                      </DataProvider>
                    </ModalContext.Provider>
                  </>
                )}
              </UserAnalyticsProvider>
            </QueryClientProvider>
          )}
        </NotificationsProvider>
      </AddinContainer>
    </ThemeProvider>
  );

  return BugsnagErrorBoundaryComponent ? (
    <BugsnagErrorBoundaryComponent
      FallbackComponent={({ error }): ReactElement => <ErrorFallback error={error} clearError={handleResetAppState} />}
    >
      {appTree}
    </BugsnagErrorBoundaryComponent>
  ) : (
    <ErrorBoundary handleResetAppState={handleResetAppState}>{appTree}</ErrorBoundary>
  );
};

export default App;
