import { EnvError } from 'mid-utils';
import { LogLevel } from '../interfaces/log';
import browserApiService from '../services/browserApiService';
import { encodeToBase64, logToFile } from './tools';
import { OutputType, Variant, VariantRfaOutput } from '@adsk/offsite-dc-sdk';
import { getSignedUrlFromDownload } from './cloudStorage';
import isNull from 'lodash/isNull';
import { AccBridgeDownloadUrlQueryParams } from 'mid-api-services';

export const editInstance = async (
  variant: Variant,
  representation: string,
  incomingAccBridgeData?: AccBridgeDownloadUrlQueryParams,
): Promise<void> => {
  logToFile('Start editInstance', LogLevel.Info);
  const { outputs, variantId, tenancyId: projectId, contentId, release, inputs } = variant;
  const sourceFolderUrn = variant.context.workspace.folderPath;

  // find first rfa output
  const rfaOutput = outputs.find(
    (output): output is VariantRfaOutput => output.type === OutputType.RFA && output.modelState === representation,
  );

  logToFile(`rfaOutput: ${JSON.stringify(rfaOutput)}`, LogLevel.Info);
  // throw error if variant doesn't have a finished rfa output
  if (!rfaOutput) {
    throw new Error(`Variant doesn't contain a RFA output.`);
  }

  if (!rfaOutput.urn) {
    throw new Error(`RFA output of variant doesn't contain an object key.`);
  }

  if (!rfaOutput.modelState) {
    throw new Error(`RFA output of variant doesn't contain a model state.`);
  }

  if (!rfaOutput.category) {
    throw new Error(`RFA output of variant doesn't contain a category.`);
  }

  const targetProjectId = incomingAccBridgeData?.targetProjectId || projectId;
  // retrieve a short-lived signed URL for download the file
  const sourceLocation =
    rfaOutput.urn.indexOf(':\\') > 0
      ? rfaOutput.urn
      : await getSignedUrlFromDownload(projectId, rfaOutput.urn, incomingAccBridgeData);
  logToFile(`Get source location, ${sourceLocation}`, LogLevel.Info);
  const engineVersion = variant.context.engine.version;
  logToFile('End editInstance, call hostApi editInstance', LogLevel.Info);

  // Encode input parameters
  const encodedInputs = encodeToBase64(JSON.stringify(inputs));

  return await browserApiService.editInstance(
    projectId,
    contentId,
    variantId,
    sourceLocation,
    rfaOutput.family,
    rfaOutput.category,
    engineVersion,
    release,
    rfaOutput.modelState,
    encodedInputs,
    sourceFolderUrn,
    targetProjectId,
  );
};

/**
 * Selects the RFA instance with the given elementId and shows the edit-in-place host window.
 */
export const selectAndEditMidInstance = async (elementId: string): Promise<boolean> => {
  if (!browserApiService.selectAndEditMidInstance) {
    throw new EnvError('selectAndEditMidInstance is not implemented in host API');
  }

  const result = await browserApiService?.selectAndEditMidInstance(elementId);
  if (!result.value) {
    throw new Error(`${result.errorMessage}`);
  }
  return result.value;
};

/**
 * Finds out if edit-in-place host window is open in Revit.
 */
export const isEditHostWindowOpen = async (): Promise<boolean> => {
  if (!browserApiService.isEditDialogOpen) {
    throw new EnvError('isEditDialogOpen is not implemented in host API');
  }

  const result = await browserApiService?.isEditDialogOpen();
  if (isNull(result.value)) {
    throw new Error(`${result.errorMessage}`);
  }
  return result.value;
};
