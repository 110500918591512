import { SelectedRFAInstance } from 'mid-addin-lib';

export interface MIDSelectedRFAInstance {
  tenancyId: string;
  targetProjectId: string;
  productId: string;
  variantId: string;
  elementId: string;
  releaseNumber?: number;
  modelState?: string;
  familyName?: string;
  sourceFolderUrn?: string;
  sourceProjectId?: string;
}

export const getMIDSelectedRfaInstanceData = (rfaInstance: SelectedRFAInstance): MIDSelectedRFAInstance => {
  const { tenancyId, contentId, variantId, release, modelState, targetTenancyId, folderUrn, elementId, familyName } =
    rfaInstance;
  let targetProjectId = tenancyId;
  let sourceProjectId;

  if (targetTenancyId && tenancyId !== targetTenancyId) {
    // it means that it is an Instance created from a Bridge Variant
    targetProjectId = targetTenancyId;
    sourceProjectId = tenancyId;
  }

  return {
    tenancyId,
    productId: contentId,
    targetProjectId,
    sourceProjectId,
    variantId,
    elementId,
    releaseNumber: release,
    modelState,
    familyName,
    sourceFolderUrn: folderUrn,
  };
};
