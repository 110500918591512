import {
  DCInput,
  InputType,
  OutputType,
  PostVariantInput,
  PostVariantOutput,
  PostVariantPayload,
} from '@adsk/offsite-dc-sdk';
import { ConfigurableProductProperties } from '@mid-react-common/common';
import { DCInputUIExtension, FormLayoutGroup, FormRules, FormRulesInputTypes } from 'mid-types';
import { getDCOutputModelStates, isDCRfaOutput, isFormRulesGroup, isValidNumber } from 'mid-utils';
import text from '../../addins.text.json';
import { FormLayoutTab } from 'mid-types';

export const transformToVariantPayloadRfaOnly = (
  configurableProductProperties: ConfigurableProductProperties,
  selectedRepresentation?: string,
): PostVariantPayload => {
  const inputPayload: PostVariantInput[] = configurableProductProperties.inputs.map((input: DCInput) => {
    const variantInput: PostVariantInput = {
      name: input.name,
      value: input.value,
      applicable: input.applicable,
    };

    return variantInput;
  });

  const outputPayload: PostVariantOutput[] = selectedRepresentation
    ? [{ type: OutputType.RFA, modelState: selectedRepresentation }]
    : configurableProductProperties.outputs.reduce<PostVariantOutput[]>((payload, output) => {
        if (isDCRfaOutput(output)) {
          const modelStates = getDCOutputModelStates(output) || [];
          return [...payload, ...modelStates.map((modelState) => ({ modelState, type: output.type }))];
        }
        return payload;
      }, []);

  const variantPayload: PostVariantPayload = {
    inputs: inputPayload,
    outputs: outputPayload,
  };

  return variantPayload;
};

export const getInputValue = (inputs: DCInput[], input: DCInput): string | number | boolean | undefined => {
  const foundInput = inputs.find((i) => i.name === input.name);

  return foundInput && foundInput.value;
};

export const updateInputValueInPlace = (inputs: DCInput[], payload: DCInput): DCInput[] =>
  inputs.map((input: DCInput) => {
    if (input.type === payload.type && input.name === payload.name) {
      return payload;
    }

    return input;
  });

const _transformInputs = (formRulesInputs: FormRulesInputTypes[], dcInputs: DCInput[]) => {
  const updatedInputs = formRulesInputs.reduce<(DCInputUIExtension<DCInput> | FormLayoutGroup)[]>((acc, input) => {
    // if current form input is not group input
    if (!isFormRulesGroup(input)) {
      // Find the matched dc input
      const currentInput = dcInputs.find((productDefinitionInput) => productDefinitionInput.name === input.name);
      // The form input no longer existed in the dc inputs
      if (!currentInput) {
        return acc;
      }
      // Update the label of the dc input with the form rule
      acc.push({
        ...currentInput,
        label: currentInput.label || input.label,
      } as DCInputUIExtension<DCInput>);
      return acc;
    }
    // Current form input is a group input
    // Each group input will have one `inputs` attribute
    const groupInputs = input.inputs.reduce<DCInputUIExtension<DCInput>[]>((groupAcc, groupInput) => {
      const groupCurrentInput = dcInputs.find((productDefinitionInput) => productDefinitionInput.name === groupInput.name);
      if (!groupCurrentInput) {
        return groupAcc;
      }
      groupAcc.push({
        ...groupCurrentInput,
        label: groupCurrentInput.label || groupInput.label,
      } as DCInputUIExtension<DCInput>);
      return groupAcc;
    }, []);

    const group: FormLayoutGroup = {
      groupName: input.groupName,
      openByDefault: input.openByDefault,
      inputs: groupInputs,
    };

    acc.push(group);
    return acc;
  }, []);
  return updatedInputs;
};

export const mapInputsToFormLayoutRules = (
  inputs: DCInput[],
  formRules?: FormRules,
): (DCInputUIExtension<DCInput> | FormLayoutGroup | FormLayoutTab)[] => {
  // If the tab exists in the rule (when tab exists, formRules wont have inputs),
  if (formRules?.tabs) {
    const updatedInputs = formRules.tabs.map<FormLayoutTab>((tab) => {
      if (!tab.inputs) {
        return {
          tabName: tab.tabName,
          tabId: tab.tabId,
          inputs: [],
        };
      }
      const tabInputs = _transformInputs(tab.inputs, inputs);
      return {
        tabName: tab.tabName,
        tabId: tab.tabId,
        inputs: tabInputs,
      };
    });
    return updatedInputs;
  }
  if (formRules?.inputs) {
    return _transformInputs(formRules.inputs, inputs);
  }
  return inputs as DCInputUIExtension<DCInput>[];
};

export const updateLabelIfReadOnlyOrNotApplicable = (
  label: string,
  readOnly: boolean | undefined,
  applicable: boolean | undefined,
): string => {
  if (applicable === false) {
    return `${label} ${text.notApplicableLabel}`;
  }

  const readOnlyLabel = readOnly ? text.readOnlyInputText : '';
  return `${label} ${readOnlyLabel}`;
};

export const getValueRange = (input: DCInput): string => {
  let valueRange = '';
  if (input.type === InputType.NUMERIC) {
    const minDefined = isValidNumber(input.min);
    const maxDefined = isValidNumber(input.max);
    const incrementDefined = isValidNumber(input.increment);

    const minDefinedOnly = minDefined && !maxDefined;
    const maxDefinedOnly = maxDefined && !minDefined;
    const minMaxBothDefined = minDefined && maxDefined;

    if (minDefinedOnly) {
      valueRange += `min ${input.min}`;
    } else if (maxDefinedOnly) {
      valueRange += `max ${input.max}`;
    } else if (minMaxBothDefined) {
      valueRange += `${input.min} to ${input.max}`;
    }

    // increment doesn't make sense without defined min value
    if (minDefined && incrementDefined) {
      if (valueRange.length) {
        valueRange += ' - ';
      }

      valueRange += `increments of ${input.increment}`;
    }
  }
  return valueRange;
};
