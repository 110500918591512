import { BatchFetchProductsResult, DCRules } from 'mid-types';
import { DynamicContent } from '@adsk/offsite-dc-sdk';
import { getContentFromObjectKey } from './cloudStorage';
import { inputsRulesToTemplateRules } from './tools';
import { InputRule } from '../interfaces/productDefinitions';
import { AccBridgeDownloadUrlQueryParams, DcApiService, inversifyContainer, InversifyTypes } from 'mid-api-services';
import { processAllSettledResults } from 'mid-utils';

export interface GetAllProductsInProjectArgs {
  projectId: string;
}

export interface GetAllProductsInBridgeFolderArgs {
  projectId: string;
  targetFolderUrn: string;
}

interface CheckAndDownloadProductRulesFromKeyArgs {
  tenancyId: string;
  rulesKey: string | undefined;
  incomingAccBridgeData?: AccBridgeDownloadUrlQueryParams;
}

const ProductsUtils = {
  getAllProductsInProject: async (
    { projectId }: GetAllProductsInProjectArgs,
    signal?: AbortSignal,
  ): Promise<DynamicContent[]> => {
    const dcApiService = inversifyContainer.get<DcApiService>(InversifyTypes.DcApiService);
    return await dcApiService.getProductsList({ projectId, signal });
  },

  getAllProductsInBridgeFolder: async (
    { projectId, targetFolderUrn }: GetAllProductsInBridgeFolderArgs,
    signal?: AbortSignal,
  ): Promise<DynamicContent[]> => {
    const dcApiService = inversifyContainer.get<DcApiService>(InversifyTypes.DcApiService);
    const productsList: DynamicContent[] = await dcApiService.getProductsList({ projectId, targetFolderUrn, signal });

    return productsList;
  },

  getProduct: async (projectId: string, productId: string): Promise<DynamicContent> => {
    const dcApiService = inversifyContainer.get<DcApiService>(InversifyTypes.DcApiService);
    return await dcApiService.getProduct(projectId, productId);
  },

  getAllProductsForProjects: async (projectIds: string[]): Promise<BatchFetchProductsResult | null> => {
    if (projectIds.length === 0) {
      return null;
    }

    const resultsPromise = projectIds.map(
      async (projectId): Promise<DynamicContent[]> => await ProductsUtils.getAllProductsInProject({ projectId }),
    );
    const allSettledResult = await Promise.allSettled<DynamicContent[]>(resultsPromise);
    const processedAllSettledResult = processAllSettledResults(allSettledResult);
    return processedAllSettledResult;
  },

  checkAndDownloadProductRulesFromKey: async ({
    tenancyId,
    rulesKey,
    incomingAccBridgeData,
  }: CheckAndDownloadProductRulesFromKeyArgs): Promise<DCRules | undefined> => {
    if (rulesKey) {
      const inputRules = await getContentFromObjectKey<InputRule[]>(tenancyId, rulesKey, incomingAccBridgeData);
      if (inputRules && inputRules.length > 0) {
        return inputsRulesToTemplateRules(inputRules);
      }
    }
  },
};

export default ProductsUtils;
