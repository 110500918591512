import { ErrorCode, CustomError } from 'mid-utils';
import { MIDSelectedRFAInstance } from './selectedRFAInstance.utils';

type CauseType = {
  rfaInstance?: MIDSelectedRFAInstance;
};

export class SelectedRFAInstanceError extends CustomError {
  errorCode = ErrorCode.SelectedRFAInstanceError;

  constructor(message: string, cause: CauseType) {
    super(message, cause);
  }
}
