import { HostApi } from './interfaces/hostApi';
import mockHostApi from './mocks/hostApi';

export function defineGlobalWindowHostApiMock(): void {
  Object.defineProperty(global.window, 'chrome', {
    value: {
      webview: {
        hostObjects: {
          hostApi: mockHostApi,
        },
      },
    },
    writable: true,
  });
}

export function defineWindowHostApiMock(win: Window, hostApi: HostApi): void {
  Object.defineProperty(win, 'chrome', {
    value: {
      webview: {
        hostObjects: {
          hostApi,
        },
      },
    },
    writable: true,
  });
}

export function resetGlobalWindowProperty(originalWindow: Window): void {
  Object.defineProperty(global.window, 'chrome', {
    value: originalWindow.chrome,
    writable: true,
  });
}
