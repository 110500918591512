import { styled } from '@mui/material/styles';

export const SelectScreenContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.var.paddingBase}px`};
  height: calc(
    100vh -
      ${({ theme }) => `${theme.var.midefHeaderWithTabsHeight + theme.var.midefFooterHeight + theme.var.borderHeight}px`}
  );
`;
