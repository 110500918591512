import { ProductRelease, Variant } from '@adsk/offsite-dc-sdk';
import { StateSetter } from '@mid-react-common/common';
import { useCachedVariantThumbnail } from '../../hooks/useCachedVariantThumbnail';
import { VariantFormState, VariantFormStates } from '../../interfaces/typings';
import { AccBridgeSourceProjectDataQueryParams } from 'mid-api-services';

export interface ModalState {
  isOpen: boolean;
  dontShowAgain?: boolean;
  dontShowAgainMessage?: string;
  title?: string | JSX.Element;
  message: string | JSX.Element;
  onConfirmCallback?: Function;
  isConfirmCallbackAsync?: boolean;
  onSecondaryConfirmCallback?: Function;
  confirmButtonLabel: string;
  cancelButtonLabel: string;
  disableConfirmButton?: boolean;
  secondaryConfirmButtonLabel?: string;
  disableSecondaryConfirmButton?: boolean;
}

interface UseProductCustomizationFooterProps {
  incomingAccBridgeData?: AccBridgeSourceProjectDataQueryParams;
  currentProductRelease: ProductRelease | undefined;
  selectedCachedVariant: Variant | null;
  selectedRepresentation: string | undefined;
  setVariantFormState: StateSetter<VariantFormStates>;
  resetConfigurableProductProperties: () => void;
  setSelectedCachedVariant: (value: React.SetStateAction<Variant | null>) => void;
  setIsFormInitializing: StateSetter<boolean>;
}

interface UseProductCustomizationFooterState {
  cachedVariantThumbnail: string | undefined;
  handleResetClick: () => void;
}

const useProductCustomizationFooter = ({
  incomingAccBridgeData,
  currentProductRelease,
  selectedCachedVariant,
  selectedRepresentation,
  setVariantFormState,
  resetConfigurableProductProperties,
  setSelectedCachedVariant,
  setIsFormInitializing,
}: UseProductCustomizationFooterProps): UseProductCustomizationFooterState => {
  const { cachedVariantThumbnail, setCachedVariantThumbnail } = useCachedVariantThumbnail({
    incomingAccBridgeData,
    currentProductRelease,
    selectedCachedVariant,
    selectedRepresentation,
  });

  const handleResetClick = (): void => {
    resetConfigurableProductProperties();
    setIsFormInitializing(true);
    setVariantFormState(VariantFormState.DEFAULT_VARIANT);
    setSelectedCachedVariant(null);
    setCachedVariantThumbnail(undefined);
  };

  return {
    cachedVariantThumbnail,
    handleResetClick,
  };
};

export default useProductCustomizationFooter;
