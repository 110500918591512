import { OutputStatus, OutputType, ProductRelease, Variant } from '@adsk/offsite-dc-sdk';
import { areAllVariantOutputsFinished } from '@mid-react-common/addins';
import {
  DEFAULT_POLLING_INTERVAL_IN_MS,
  NOTIFICATION_STATUSES,
  ShowNotificationProps,
  StateSetter,
} from '@mid-react-common/common';
import { AccBridgeSourceProjectDataQueryParams, getDcApiServiceInstance } from 'mid-api-services';
import { ProductReleaseError, logError } from 'mid-utils';
import { useEffect, useMemo } from 'react';
import text from '../revit-components.text.json';
import { useQuery, Query } from '@tanstack/react-query';
import { DCProductUIExtension } from 'mid-types';

interface UseFetchingListOfVariantsProps {
  incomingAccBridgeData?: AccBridgeSourceProjectDataQueryParams;
  currentProductRelease: DCProductUIExtension<ProductRelease> | undefined;
  cachedVariantsList: Variant[];
  reFetchCachedVariants: boolean;
  postedVariantIdsList: string[];
  setReFetchCachedVariants: StateSetter<boolean>;
  showNotification: (props: ShowNotificationProps) => void;
}

interface UseFetchingListOfVariantsState {
  filteredVariantsListByCurrentRelease: Variant[] | undefined;
  isLoadingVariantsList: boolean;
  handleRefreshVariants: () => void;
}

// Check if every cached variant has outputs with status SUCCESS or FAILED (finished statuses)
const areAllCachedVariantsGenerated = (variants: Variant[] | undefined) => {
  if (!variants) {
    return true;
  }
  return variants.every((variant) => areAllVariantOutputsFinished(variant.outputs));
};

const VARIANTS_LIST_KEY = 'variantsList';

const useFetchingListOfVariants = ({
  incomingAccBridgeData,
  currentProductRelease,
  cachedVariantsList,
  reFetchCachedVariants,
  postedVariantIdsList,
  setReFetchCachedVariants,
  showNotification,
}: UseFetchingListOfVariantsProps): UseFetchingListOfVariantsState => {
  const fetchListOfVariants = async (signal?: AbortSignal) => {
    if (!currentProductRelease?.contentId) {
      throw new ProductReleaseError(text.listVariantsMissingSelectedProductRelease, {
        currentProductRelease,
      });
    }

    try {
      return getDcApiServiceInstance().getVariantsList({
        projectId: currentProductRelease.tenancyId,
        productId: currentProductRelease.contentId,
        incomingAccBridgeData,
        signal,
      });
    } catch (err) {
      logError(err);
      showNotification({
        message: text.failedToLoadCachedVariants,
        severity: NOTIFICATION_STATUSES.ERROR,
      });
    }
  };

  const {
    data: fetchedVariantsList,
    refetch: refetchVariantsList,
    isLoading: isLoadingVariantsList,
  } = useQuery<Variant[] | undefined>({
    queryKey: [VARIANTS_LIST_KEY, currentProductRelease?.tenancyId, currentProductRelease?.contentId],
    queryFn: ({ signal }) => fetchListOfVariants(signal),
    enabled: currentProductRelease && !areAllCachedVariantsGenerated(cachedVariantsList),
    refetchInterval: (current: Query<Variant[] | undefined>) => {
      if (!current.state.data || !areAllCachedVariantsGenerated(current.state.data)) {
        return DEFAULT_POLLING_INTERVAL_IN_MS;
      }
      return false;
    },
  });

  const filteredVariantsListByCurrentRelease = useMemo(() => {
    if (!fetchedVariantsList || !currentProductRelease) {
      return;
    }
    return fetchedVariantsList
      .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
      .filter(
        (variant) =>
          variant.release === currentProductRelease.release &&
          // if variant has been posted within this session, show it, even if it failed
          (postedVariantIdsList.includes(variant.variantId) ||
            variant.outputs.some(
              (output) =>
                (output.type === OutputType.RFA && output.status === OutputStatus.SUCCESS) ||
                (output.type === OutputType.RFA && output.status === OutputStatus.PENDING),
            )),
      );
  }, [fetchedVariantsList, currentProductRelease, postedVariantIdsList]);

  useEffect(() => {
    if (reFetchCachedVariants) {
      refetchVariantsList();
      setReFetchCachedVariants(false);
    }
  }, [reFetchCachedVariants, setReFetchCachedVariants, refetchVariantsList]);

  const handleRefreshVariants = () => {
    setReFetchCachedVariants(true);
  };

  return { filteredVariantsListByCurrentRelease, isLoadingVariantsList, handleRefreshVariants };
};

export default useFetchingListOfVariants;
