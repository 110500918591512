import { EnvError, logError } from 'mid-utils';
import { InformedDesignInstance, SelectedRFAInstance } from '../interfaces/selectedRFA';
import text from '../mid-addin-lib.text.json';
import browserApiService from '../services/browserApiService';
import { BrowserApiServiceResult } from '../interfaces/browserApi';

/**
 * Retrieves the tenancyId, contentId and variantId of the selected RFA Instance.
 * @returns Selected RFA Instance tenancyId, contentId and variantId.
 * @throws If the JSON returned by the host API cannot be parsed.
 */
export const getSelectedRFAInstance = async (): Promise<SelectedRFAInstance> => {
  if (!browserApiService.getSelectedRFAInstance) {
    throw new EnvError('getSelectedRFAInstance is not implemented in host API');
  }

  try {
    return JSON.parse(await browserApiService.getSelectedRFAInstance());
  } catch {
    throw new Error(text.failedToDeserializeSelectedRFAInstance);
  }
};

export const getAllMIDInstancesData = async (): Promise<InformedDesignInstance[]> => {
  if (!browserApiService.getAllMIDInstancesData) {
    throw new EnvError('getAllMIDInstancesData is not implemented in host API');
  }

  try {
    return JSON.parse(await browserApiService.getAllMIDInstancesData());
  } catch (e) {
    logError(e);
    throw new Error(text.failedToFetchInformedDesignInstanceData);
  }
};

export const selectAndEditMidInstance = async (elementId: string): Promise<BrowserApiServiceResult<boolean>> => {
  if (!browserApiService.selectAndEditMidInstance) {
    throw new EnvError('selectAndEditMidInstance is not implemented in host API');
  }

  try {
    return await browserApiService.selectAndEditMidInstance(elementId);
  } catch (e) {
    logError(e);
    throw new Error(text.failedToOpenInstance);
  }
};
