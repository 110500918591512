import { DCInput } from '@adsk/offsite-dc-sdk';
import { FieldSetContainer, MIDEmptyState } from '@mid-react-common/common';
import { Divider } from '@mui/material';
import { FormLayoutGroup, DCInputUIExtension } from 'mid-types';
import { ForgeValidationError, isFormLayoutGroup } from 'mid-utils';
import { ProductCustomizationFormWrapper, BottomDivider } from '../ProductCustomization.styles';
import { ProductCustomizationFormGroup } from './ProductCustomizationFormGroup';
import { ProductCustomizationFormInputs } from './ProductCustomizationFormInputs';
import { productCustomizationTestIds } from '../../../dataTestIds';
import text from '../../../addins.text.json';

const { productCustomizationForm } = productCustomizationTestIds;

interface ProductCustomizationFormWithoutTabProps {
  inputs: (DCInputUIExtension<DCInput> | FormLayoutGroup)[];
  inputsError?: ForgeValidationError;
  isProductConfigurable?: boolean;
  inactive?: boolean;
  error?: Error;
  handleInputUpdate: (payload: DCInput) => Promise<void>;
  setIsFormDataValid?: (isFormDataValid: boolean) => void;
}

export const ProductCustomizationFormWithoutTabs: React.FC<ProductCustomizationFormWithoutTabProps> = ({
  inputs,
  inputsError,
  inactive,
  error,
  isProductConfigurable,
  handleInputUpdate,
  setIsFormDataValid,
}) => {
  return (
    <ProductCustomizationFormWrapper>
      <FieldSetContainer data-testid={productCustomizationForm} fullWidth={!!error}>
        {inputs.length <= 0 && (
          <MIDEmptyState
            title={text.formIsEmpty}
            warning={isProductConfigurable ? text.formMustIncludeOneInput : undefined}
          />
        )}
        {inputs.length > 0 &&
          !error &&
          inputs.map((input: DCInputUIExtension<DCInput> | FormLayoutGroup, index) => {
            if (isFormLayoutGroup(input)) {
              return (
                <div key={`${input.groupName}-${index}-${input.openByDefault.toString()}`}>
                  {/** If the group is not the first one, add a divider */}
                  {index !== 0 ? <Divider flexItem /> : null}
                  <ProductCustomizationFormGroup
                    formLayoutGroup={input}
                    inputsError={inputsError}
                    inactive={inactive}
                    handleInputUpdate={handleInputUpdate}
                    setIsFormDataValid={setIsFormDataValid}
                    isProductConfigurable={isProductConfigurable}
                  />
                  {/** If the group is not the last one, add a divider */}
                  {index !== inputs.length - 1 ? <BottomDivider flexItem /> : null}
                </div>
              );
            }
            return (
              <ProductCustomizationFormInputs
                currentInput={input}
                inputsError={inputsError}
                inactive={inactive}
                handleInputUpdate={handleInputUpdate}
                setIsFormDataValid={setIsFormDataValid}
                key={`${input.name}-${index}`}
                isProductConfigurable={isProductConfigurable}
              />
            );
          })}
      </FieldSetContainer>
    </ProductCustomizationFormWrapper>
  );
};
